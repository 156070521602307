<template>
    <div class="pa-4">

        <!--Page Loader-->
        <pageLoader :is-page-loading="isPageLoading"/>

        <!--Filters-->
        <div class="d-flex">
            <app-input input-type="textInput" v-if="$vuetify.breakpoint.width > 600"
                style="width:100%;"
                class=""                             
                label= 'Name'
                clearable
                :append-icon="'icons8-search'"
                v-model.trim="searchName"/>
            <app-input input-type="textInput" v-if="$vuetify.breakpoint.width <= 600 && matchArray.length != 0"
                style="width:100%;"
                class=""                             
                label="Name"
                :append-icon="'icons8-search'"
                clearable
                v-model.trim="searchName"/>
            <app-input v-if="$vuetify.breakpoint.width > 600" input-type="select"
                    :items="sortPositions"
                style="width:100%;"
                class="ml-4"              
                clearable              
                label='Position'
                :append-icon="'icons8-search'"
                v-model="searchPosition"/>
            <app-input v-if="$vuetify.breakpoint.width > 600" input-type="textInput"
                style="width:100%;"
                class="ml-4"       
                clearable                     
                label='Opponent'
                :append-icon="'icons8-search'"
                v-model.trim="searchOpponent"/>
            <app-input v-if="$vuetify.breakpoint.width > 600" input-type="textInput"
                style="width:100%;"
                class="ml-4"                             
                label= 'Date'
                clearable
                :append-icon="'icons8-search'"
                v-model.trim="searchDate"/>
        </div>
       
        <!--Table-->
        <v-data-table v-if="$vuetify.breakpoint.width > 600"
            class="rounded-lg mt-4"
            :headers="computedHeaders"
            :items="computedTableData"
            item-key="id"
            :items-per-page="MIX_itemsPerPage(MIX_breakpoint())"
            :search="search"
            >

            <!--match Date-->
            <template v-slot:[`item.matchDate`]="{ item }">
                <app-text category="text-small">{{dayTimeFormat(item.matchDate, 'long')}}</app-text>
            </template>
            <!--match Duration-->
            <template v-slot:[`item.matchDuration`]="{ item }">
                <app-text category="text-small">{{ item.matchDuration}} mins</app-text>
            </template>
                <!--PLayer Name-->
            <template v-slot:[`item.userName`]="{ item }">
                <app-text category="text-small">{{ item.userName}} </app-text>
            </template>
            <!--Position-->
            <template v-slot:[`item.matchPlayerPostition`]="{ item }">
                <app-text category="text-small">{{ item.matchPlayerPostition}} </app-text>
            </template>
            <!--match Opponent-->
            <template v-slot:[`item.matchOpponent`]="{ item }">
                <app-text category="text-small">{{ item.matchOpponent}} </app-text>
            </template>
            <!--match Feeling-->
            <!-- <template v-slot:[`item.matchFeeling`]="{ item }">
                <div>
                    <v-icon v-if="item.matchFeeling === 'sad'" class="icons8-sad red--text"/>
                    <v-icon v-if="item.matchFeeling === 'neutral'" class="icons8-neutral accent--text"/>
                    <v-icon v-if="item.matchFeeling === 'happy'" class="icons8-happy update--text"/>
                </div>
            </template> -->
            <!--Actions-->
            <template v-slot:[`item.actions`]="{item}">
                <v-btn class="primary--text white"
                    depressed @click="openRightPanel(item)">Open
                    <v-icon class="icons8-forward pa-0" color="primary"/>
                </v-btn>
            </template>
        </v-data-table>

        <!--Mobile-->
        <div v-if="$vuetify.breakpoint.width < 600">

            <!--No data information-->
            <div  class="text-center">
                <app-text v-if="matchArray.length === 0" category="text-default" >There is nothing to show</app-text>                            
            </div>
            <!--Match Card And Details-->
            <v-card class="my-4 pa-4 align-center full-width" elevation="0" v-for="item in computedTableData" :key="item.id" @click="openRightPanel(item)">
                <div class="full-width">
                    <v-row>
                        <v-col cols="12" xs="12" class="d-flex align-center full-width">
                            <v-row>
                                <v-row class="ma-0" style="cursor: pointer">

                                    <!--Date-->
                                    <v-col cols="6" xs="6" style="border-right: 1px solid lightgrey" class="d-flex align-center">
                                        <div class="text-center"><v-icon class="primary--text">icons8-calendar-2</v-icon></div>
                                        <app-text category="text-small" class="ml-2 grey--text">{{dayTimeFormat(item.matchDate, 'short')}}</app-text>
                                    </v-col>
                                    <!--Opponent -->
                                    <v-col cols="6" xs="6" class="d-flex align-center ">
                                        <div class="text-center"><v-icon  class="primary--text">icons8-rugby-3</v-icon></div>
                                        <app-text category="text-small" class="ml-2 grey--text">{{item.matchOpponent}}</app-text>
                                    </v-col>
                                    <!--Fatigue-->
                                    <v-col cols="6" xs="6"  style="border-right: 1px solid lightgrey" class="mt-2 d-flex align-center">
                                        <div class="text-center"><v-icon  class="primary--text">icons8-sleep</v-icon></div>
                                        <app-text category="text-small" class="ml-2 grey--text">{{item.matchFatigue}}</app-text>
                                    </v-col>
                                    <!--Performance-->
                                    <v-col cols="6" xs="6" class="d-flex align-center ">
                                        <div class="text-center"><v-icon  class="primary--text">icons8-speed</v-icon></div>
                                        <app-text category="text-small" class="ml-2 grey--text">{{item.matchPerformance}}</app-text>
                                    </v-col>
                                    <!--Player Position-->
                                    <v-col cols="12" xs="12">
                                        <app-text category="text-medium-bold" class="primary--text">{{item.matchPlayerPostition}}</app-text>
                                        <v-divider class="mt-2 mb-2"/>
                                    </v-col>

                                </v-row>

                                <!--Chat Last message-->
                                <v-col cols="12" xs="12" style="cursor: pointer">
                                    <div class="text--primary d-flex align-center">
                                        <div class="text-center">
                                             <app-icon size="48" color="grey" class="mr-4"
                                            :icon="currUserData.userName != item.matchComments[item.matchComments.length -1].user ? 'users' : 'whistle'">
                                            </app-icon>
                                        </div>
                                        <div>
                                            <span style="text-size-adjust:50%" class="caption font-weight-bold">{{dayTimeMinFormat(item.matchComments[item.matchComments.length-1].created_at)}}</span><br/>
                                                {{returnSubstring(item.matchComments[item.matchComments.length -1].content)}}
                                        </div>
                                    </div>
                                </v-col>

                            </v-row>						
                        </v-col>
                    </v-row>
                </div>
            </v-card>

            <!--Load More Buttons-->
            <div cols="12" xs="12" class="d-flex mt-5 align-center justify-center full-width" v-if="computedLoadButton">
                <div><v-btn width="150" depressed large class="primary" @click="loadMore()">Load More</v-btn></div>
            </div>
        </div>
                
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {

        name: "matches",

        data: () => ({
            matchArray : [],
            reasasibleMatchArray :[],

            search:"",
            currUserData:'',

            //filters
            searchName:"",
            searchPosition:"",
            searchOpponent:"",
            searchDate:"",

            showMoreButton: true,
            isPageLoading: true,
           
            // Table headers
            headers: [
                { text: "ID", value: "id", align: "left", sortable: false, hidden: true, hide: false, hasAccess: ['Staff-Admin']},
                { text: "Player Name", value: "userName", align: "left",sortable: false, hidden: false, hide: false,},
                { text: "Position", value: "matchPlayerPostition", align: "left",sortable: false, hidden: false, hide: false,},
                { text: "Opponent", value: "matchOpponent", align: "left", sortable: false, hidden: false, hide: false,},
                { text: "Performance", value: "matchPerformance", align: "left",sortable: false, hidden: true, hide: false,},
                { text: "Date", value: "matchDate", align: "left", sortable: false, hidden: false, hide: false,},
                { text: "Fatigue",value: "matchFatigue", align: "left", sortable: false, hidden: true, hide: false,},
                { text: "Feeling",value: "matchFeeling", align: "left", sortable: false, hidden: true, hide: false,},
                { text: "", value: "actions", align: "left", sortable: false,hasAccess: ['Staff-Admin','Player-User','Coach-User'], hidden: false, hide: false,width: "100px",},
            ],
        }),

        computed: {
            ...mapGetters({
                GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
            }),

             /**
             * Computed Table Data
             *
             * Return a sorted array of JSON objects.
             *
             * @returns {Array} Array of processed JSON data
             */
            computedTableData() {
                const t = this
                let tableData = t.matchArray
                if(t.searchName){
                    tableData = tableData.filter(data =>
                      data.userName.toUpperCase().match(t.searchName.toLocaleUpperCase()))
                }
                if(t.searchPosition){
                    tableData = tableData.filter(data =>
                      data.matchPlayerPostition.toUpperCase().match(t.searchPosition.toLocaleUpperCase()))
                }
                if(t.searchOpponent){
                    tableData = tableData.filter(data =>
                      data.matchOpponent.toUpperCase().match(t.searchOpponent.toLocaleUpperCase()))
                }
                if(t.searchDate){
                    tableData = tableData.filter(data =>
                    // t.$moment(Number(data.sessionDate)).format('dddd Do MMMM \'YY').toUpperCase().match(t.searchDate.toUpperCase()))
                    this.$moment(data.matchDate, 'X').format('dddd Do MMMM \'YY').toUpperCase().match(t.searchDate.toUpperCase()))
                }
                // Sort alphabetically
                tableData = tableData.sort((a, b) => {
                    return b.matchDate > a.matchDate ? 1 : -1
                })
                return tableData
            },

            /**
             * Computed Headers
             *
             * Only display headers that are not set to hidden and the user has access to (via userLevel).
             *
             * @returns {[headers]} - filtered headers to render in the table
             */
            computedHeaders() {
                const t = this
                const headers = t.headers
                let filteredHeaders = []
                headers.forEach(header => {
                    // Only Admins can view the OPEN button
                    if (header.value === 'actions' && !header.hasAccess.includes(t.currUserData.userLevel)) {
                        return
                    }
                    // If the column is not hidden, show it
                    if (!header.hidden) {
                        filteredHeaders.push(header)
                    }
                })
                return filteredHeaders
            },

            /**
             * This function detects if the matches of the user are more than 4 if yes then shows the load more button
             */
            computedLoadButton(){
                if(this.reasasibleMatchArray.length >4 && this.matchArray.length !=this.reasasibleMatchArray.length){
                    return true;
                }else{
                    return false;
                }
            },

            /**
             * Returns player positions in alphabetical order
             */
            sortPositions(){
                return this.playersPositions.sort()
            }

        },


        methods: {
            /**
             *  Returns the last message of the chat 
             * and if it has more than 30 characters reduce it
             */
            returnSubstring(message){
                if(message.length >30){
                    return message.substring(0,30)+".."
                }else{
                    return message
                }
            },

            /**
             * This method return the date in a nice format
             * @return The date
             */
             dayTimeFormat(date, shortLong){
                let formatedDate ="";
                if( shortLong === "short"){
                    formatedDate = this.$moment(date, 'X').format('DD MMM YY');
                }else if(shortLong === 'long'){
                    formatedDate = this.$moment(date, 'X').format('dddd Do MMMM \'YY');
                }
               return formatedDate
            },

            /**
             * This method return the date of the last message in a nice format
             * @return The date of the last message
             */
            dayTimeMinFormat(item){
                return this.$moment(item, 'X').format('DD MMM YY | HH:mm');
            },

            /**
             * This method detects if there are more mattches to be show if yes 
             * load those matches and display them
             */
            loadMore(){
                let remainMatches = this.matchArray.length - this.reasasibleMatchArray.length;
                if(remainMatches > 0 && remainMatches> 5){
                    for (var i=0; i<5; i++){
                        this.reasasibleMatchArray.push(this.matchArray[this.reasasibleMatchArray+i])
                    }
                }else if(remainMatches <= 0 ){
                    this.showMoreButton = false;
                }else{
                    this.reasasibleMatchArray = this.matchArray;
                    this.showMoreButton = false;
                }
            },

            /**
             * lIMITS the possible matches reviews available
             */
            limitSessionView(){
                if(this.matchArray.length > 5){
                    for (var i=0; i<5; i++){
                        this.reasasibleMatchArray.push(this.matchArray[i])
                    }
                }else{
                    this.reasasibleMatchArray = this.matchArray
                }
            },

            /**
             * Open Right Panel
             *
             * Open the CRUD form.
             * If the form is opened using the new button, it will be blank.
             * If the form is opened from the table, it will be assigned here and populated.
             *
             * @param item JSON containing the selected data, if required
             */
            openRightPanel(item) {               
                this.$emit('rightPanel',this.event);
                this.$emit("selectedSession", item);
            },

            /**
             * Reads and sets User data
             */
            async readUserData(){
                let userDataResult = await this.MIX_firestore_read(this.GET_FIREBASE_userAuth.uid, 'users');
                this.currUserData = userDataResult.data;
            },

            /**
             * Read the matchs that corrispond to the user
             */
            async readMatches(){
                this.matchArray = await await this.MIX_firestore_allData("matchReviews");
                this.limitSessionView();
            }
        },
        async created(){
            this.readUserData();
            this.readMatches();
            this.isPageLoading=false;
        }

    }
</script>

<style scoped>
    .borderLeft {
        border-left: 4px solid #999999;
        min-height: calc(100vh - 128px);
    }
</style>
