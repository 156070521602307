<template>
   <v-row class="d-flex justify-center" no-gutters>
        <v-col cols="12" xs="12" sm="12" md="10" lg="8" class="">

            <!--Logo-->
            <v-row class="pt-4 px-5 justify-center">
                <v-col cols="12" xs="12" md="12">
                    <v-img class="rounded-pill" src="@/assets/logo.png" style="text-align:center ; margin:auto" contain width="256" height="auto"></v-img>
                </v-col> 
            </v-row>

            <!--Reset Password -->
            <v-row class="d-flex justify-center mt-1">
                <v-col cols="12" xs="12" sm="6"  class="px-6"> 
                    <div class="text-h6 font-weight-normal primary--text ">Reset Password</div>
                    <p class="mt-4 mb-0 text-left body2">Please enter your email address to request a password reset.</p>
                    <v-divider></v-divider>
                    <v-form class="my-4"> 
                        <v-text-field ref="email" dense filled background-color="white" v-model.trim="email" label="Email" append-icon="icons8-envelope" 
                            hide-details="auto" 
                            data-vv-name="email" 
                            required :rules="rules.email">
                        </v-text-field>
                        <v-row class="my-4 text-center">
                            <div v-if="showDone" class="pl-4 body-2 error--text">A link to reset your password has been sent to your email address. Check your inbox.</div>
                            <v-col cols="12" class="justify-center">
                                <v-btn block depressed @click.native="resetPassword()" large class="my-2 mb-4 primary">Reset Password</v-btn>
                                <router-link :to="{ path: 'login' }" replace class="btn btn-link" >Return to Login</router-link>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
            </v-row>   
                  
        </v-col>     
    </v-row>    
</template>

<script>
    export default {
        name: "ResetPassword",
        // * DATA
        data: () => ({
            email: "",
            rules: [],
            showDone: false
        }),
        methods: {
            resetPassword: function() {
                let t = this;
                t.email = t.email.toLowerCase();
                t.rules = {
                    email: [(v) => !!v || 'Email is Required', (v) => /.+@.\..+/.test(v) || 'Email must be valid']
                }
                t.$nextTick(() => {
                    if (t.$refs.email.validate()) {
                        t.$firebase.auth
                            .sendPasswordResetEmail(t.email)
                            .then(function() {
                                t.showDone = true
                                t.MIX_alertBox({ color: 'success', timeout: 2000, message: 'A link to reset your password has been sent to your email address. Check your inbox', show: true })
                            })
                            .catch(function(error) {
                                t.MIX_alertBox({ color: 'error', timeout: 2000, message: error, show: true })
                            });
                    } else {
                        t.MIX_alertBox({ color: 'warning', timeout: 2000, message: 'Fill out your email address correctly in order to proceed.', show: true })
                    }
                })
            },
        },
    };
</script>