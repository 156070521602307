
export default {
	appname: 'Development Passport',
	organisation: 'Gryphons',
	primary: "#000000",
	secondary: "#FFFFFF",
	accent: "#000000",
	neutral:"#F2C04C",
	update: "#B8E110",
	error: "#b71c1c",
	adminEmail: "your administrator",
	appBackground: "#f5f5f5", // #000000
	text: "#444444"
}
