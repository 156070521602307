<!--
Close Icon

Simple 'X' close icon with a little hover styling.
-->
<template>
    <v-icon class="closeIcon icons8-close"
            size="32"/>
</template>

<script>
export default {

    name: "closeIcon",

    props: ['color']

}
</script>

<style scoped>
    .closeIcon {
        border-radius: 50%;
        color: grey;
        padding: 8px;
        transition: 0.5s;
    }

    .closeIcon:hover {
        background: var(--v-error-base);
        color: white;
        cursor: pointer;
        transition: 0.5s;
    }
</style>
