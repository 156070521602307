<!--ALERTBOX COMPONENT-->
<template>
	<v-snackbar dark bottom v-model="GET_alertBox.show" :color="GET_alertBox.color" :timeout="GET_alertBox.timeout">
		<span class="white--text">{{ GET_alertBox.message }}</span>
		<template v-slot:action="{ attrs }">
			<v-btn color="white" text v-bind="attrs" @click="MIX_alertBox({ color: '', timeout: -1, message: '', show: false })"> Close </v-btn>
		</template>
	</v-snackbar>
</template>

<script>
	import { mapGetters } from 'vuex';
	export default {
		computed: {
			...mapGetters({
				GET_alertBox: "GET_alertBox",
			})
		}
	};
</script>
