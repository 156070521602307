<template>
    <div class="pa-4">

        {{ computedFormData }}

        <pageLoader :is-page-loading="isPageLoading"/>

        <div class="d-flex align-center">
            <!--Form-->
            <v-form v-model="valid" lazy-validation style="width:100%;">
                <!--Date Selection-->
                <div>
                    <app-text category="text-medium" class="grey--text">Date of Session</app-text>
                    <v-menu v-model="dateMenu" :rules="sessionDateRule" :close-on-content-click="false" transition="scale-transition" offset-y 
                        max-width="290px" 
                        min-width="auto" 
                        required>
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field :disabled="computedIsFormReadOnly" dense background-color="white" outlined v-model="computedDate" 
                                hide-details="auto" 
                                append-icon="mdi-calendar" 
                                class="rounded-lg mt-2"
                                height="48px"
                                :error="errors.date"
                                :error-messages="errors.dateErrorMessage"
                                flat
                                filled
                                readonly 
                                v-bind="attrs"
                                v-on="on">
                            </v-text-field>
                        </template>
                        <v-date-picker v-model="date" no-title @input="dateMenu = false" :max="disableFutureDate"></v-date-picker>
                    </v-menu>
                </div>

                <!--Session Duration-->
                <div class="mt-8">
                    <app-text category="text-medium" class="grey--text">Session Duration (mins)</app-text>                                    
                    <v-slider :readonly="computedIsFormReadOnly" required v-model="form.sessionDuration" :max="120" step="5" :ticks="false"
                        tick-size="5" 
                        class="mt-8 "
                        height="48px"
                        :error="errors.sessionDuration"
                        :error-messages="errors.sessionDurationErrorMessage"
                        :rules="sessionDurationRule"
                        thumb-label="always" 
                        hide-details="auto">
                    </v-slider>
                </div>

                <!--Session Skill-->
                <div class="mt-8">
                    <app-text category="text-medium" class="grey--text">Skill</app-text>
                    <app-input input-type="comboBox"
                        :disabled="computedIsFormReadOnly"                                            
                        class="mt-2"
                        hide-details="auto"
                        :error="errors.sessionSkill"
                        :error-messages="errors.sessionSkillErrorMessage"
                        :items="readSkillAndSort"
                        item-text="name"
                        item-value="name"
                        :returnObject="false"
                        appendIcon="icons8-calendar-2"
                        v-model="form.sessionSkill"
                        />                       
                </div>


                <!--Coach-->
                <div class="mt-8">
                    <app-text category="text-medium" class="grey--text">Coach</app-text>
                    <app-input input-type="comboBox"
                        :disabled="computedIsFormReadOnly"                                            
                        class="mt-2"
                        hide-details="auto"
                        :error="errors.sessionCoach"
                        :error-messages="errors.sessionCoachlErrorMessage"
                        :items="coachArray"
                        item-text="userName"
                        item-value="userName"
                        :returnObject="false"
                        appendIcon="icons8-calendar-2"
                        v-model="form.sessionCoach"
                        />                       
                </div>
                
                <!--Comment-->
                <div class="mt-8">
                    <app-text category="text-medium" class="grey--text">How was your session?</app-text> 
                    <app-input input-type="textArea"
                        :disabled="computedIsFormReadOnly"                                            
                        class="mt-2"
                        :error="errors.sessionComments"
                        :error-messages="errors.sessionCommentsErrorMessage"
                        hide-details="auto" 
                        v-model="commentForm.content"
                        :rows="4"/>
                </div>


                <!--Session rating-->
                <div class="mt-8">
                    <app-text category="text-medium" class="grey--text">Rate your Session?</app-text> 
                    <v-slider :readonly="computedIsFormReadOnly" required v-model="form.sessionRating" :min="1" :max="10" step="1"
                        :ticks="false" 
                        tick-size="1"
                        :error="errors.sessionRating"
                        :error-messages="errors.sessionRatingErrorMessage"
                        height="48px"
                        class="mt-8"
                        thumb-label="always"
                        hide-details="auto">
                    </v-slider>
                </div>

               

                <!--Feelings-->
                <div class="mt-8">
                    <app-text category="text-medium" class="grey--text">How you feeling today?</app-text>                              
                    <!--ReadOnly Feeling-->
                    <div v-if="computedIsFormReadOnly" class="d-flex  align-center  justify-center  full-width">
                        <div v-for="(item,index) in feelingArray" :key="index">
                            <v-icon v-model="form.sessionFeeling" :class="returnActiveIcon(item.text)" class="feeling" >{{item.icon}}</v-icon>
                        </div>
                    </div>
                    <!--Editable Feelings-->
                    <div v-else  class="d-flex  align-center  justify-center  full-width">
                        <div  v-for="(item,index) in feelingArray" :key="index">
                            <v-icon v-model="form.sessionFeeling" :class="returnActiveIcon(item.text)" class="feeling" 
                                    @click="form.sessionFeeling=item.text" >{{item.icon}} </v-icon>
                        </div>
                    </div>
                </div> 
            </v-form>
        </div>

    <!--Buttons-->
        <div class="d-flex mt-8">
            <v-col cols="6">
                <app-btn v-if="!computedIsFormReadOnly" @click.native="$emit('emitCloseRightPanel')" block depressed large color="grey lighten-2" 
                    icon="icons8-cancel"
                    label="CANCEL"/>
            </v-col>
            <v-col cols="6">
                <app-btn v-if="!computedIsFormReadOnly && formData != ''" block depressed large @click.native="updateSession" color="success"
                    icon="icons8-save"
                    label="SAVE"/>
                <app-btn v-else-if="!computedIsFormReadOnly" @click.native="saveSession" block depressed large color="success" 
                    icon="icons8-save"
                    label="SAVE"/>
            </v-col>
        </div>

    </div>
</template>

<script>

    import {mapGetters, } from "vuex";

    export default {

        name: "sessionForm",
        props: ['doesFormStartReadOnly', 'formData'],

        data: (vm) => ({
            date: vm.$moment().format("YYYY-MM-DD"),
            dateMenu: false,

            // Feelings
            feelingArray: [ {text:'sad', icon:'icons8-sad'}, 
                            {text:'neutral', icon:'icons8-neutral'},
                            {text:'happy', icon:'icons8-happy'}],

            isPageLoading:true, //loader
            isFormReadOnly: false,

            // User Data
            skillsArray: [],
			currUserData : "",
            coachArray: [],

            //User Session Form
			form: {
				id:"",
				userId: "",
				sessionDuration: 15,
				sessionDate: "",
				sessionComments: [],
				sessionSkill: "",
				sessionFeeling: "",
				sessionRating: 3,
				sessionCreatedAt: "",
                sessionCoach:"",
                currentPosition:"",
			},

            errors: {
                sessionCoach:false,
                sessionCoachErrorMessage:"",
                sessionDate:false,
                sessionDateErrorMessage:"",
                sessionDuration:false,
                sessionDurationErrorMessage:"",
                sessionSkill:false,
                sessionSkillErrorMessage:"",
                sessionComments:false,
                sessionCommentsErrorMessage:"",
                sessionRating:false,
                sessionRatingErrorMessage:"",
                sessionFeeling:false,
                sessionFeelingErrorMessage:"",
            },

            //Reference for session feedback chat
			commentForm: {
				content: "",
				user: "",
				created_at: ""
			},

            //Rules
			valid: true,
			sessionDurationRule: [(v) => !!v || "Session duration is required"],
			sessionDateRule: [(v) => !!v || "Session date is required"],
			sessionCommentsRule: [(v) => !!v || "Session comments are required"],
			sessionSkillRule: [(v) => !!v || "Session skill is required"],
			sessionRatingRule: [(v) => !!v || "Session rating is required"],
        }),

        computed: {

            ...mapGetters({ 
                GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
            }),

            /**
             * Computed From Data
             *
             * If this is an existing document, set the form data.
             * If it's not, the default form will be used.
             */
            computedFormData() {
                const formData = this.$props.formData
                if (formData?.id) {
                    // Assign data
                    this.form = formData
                    this.commentForm.content =  this.form.sessionComments[0].content;
                    this.date = this.$moment(this.form.sessionDate, "X").format("YYYY-MM-DD")
                }
            },

            /**
             * Computed Date
             */
            computedDate() {
                return this.$moment(this.date, "YYYY-MM-DD").format("DD-MMM-YYYY");
            },

            /**
             * Computed Is Form Read Only
             *
             * When the form is first created in the Supervisor's Control Panel,
             * the form should be editable.
             * If the form is open through the Reporting pages, it should open uneditable.
             *
             * @returns {boolean} is form read only
             */
            computedIsFormReadOnly() {
                return this.$props.doesFormStartReadOnly
                    ? this.$props.doesFormStartReadOnly
                    : this.isFormReadOnly
            },

            /**
             * This method return a sort array
             * @returns The skills array sorted.
             */
            readSkillAndSort(){
                return this.$sortBy(this.skillsArray, 'name')
            },
            
            /**
             * Disable future dates
             */
             disableFutureDate(){
                return new Date().toISOString().substr(0, 10);
            }
        },

        methods: {

            /**
             * Fvalidation of the form 
             */
            validateForm() {
                const t = this
                t.errors.sessionCoach = false;
                t.errors.sessionCoachErrorMessage ='';
                t.errors.sessionDate = false
                t.errors.sessionDateErrorMessage = ''
                t.errors.sessionDuration = false
                t.errors.sessionDurationErrorMessage = ''
                t.errors.sessionSkill = false
                t.errors.sessionSkillErrorMessage = ''
                t.errors.sessionComments = false
                t.errors.sessionCommentsErrorMessage = ''
                t.errors.sessionRating = false
                t.errors.sessionRatingErrorMessage =''
                t.errors.sessionFeeling = false,
                t.errors.sessionFeelingErrorMessage = ''

                // Date
                if (!t.form.sessionDate.trim()) {
                    t.errors.sessionDate = true
                    t.errors.sessionDateErrorMessage = 'Session Date required'
                }
                // Duration
                else if (t.form.sessionDuration < 1 ) {
                    t.errors.sessionDuration = true
                    t.errors.sessionDurationErrorMessage = 'Session date neeeds to be more than  0'
                }

                // Skill 
                if (!t.form.sessionSkill.trim()) {
                    t.errors.sessionSkill = true
                    t.errors.sessionSkillErrorMessage = 'Session Skill required'
                }
                // Skill 
                if (!t.form.sessionCoach.trim()) {
                    t.errors.sessionCoach = true
                    t.errors.sessionCoachMessage = 'Session Coach required'
                }
                // Comment
                if (t.commentForm.content == '') {
                    t.errors.sessionComments = true
                    t.errors.sessionCommentsErrorMessage = 'Session Comment required'
                }
                // rating
                if (t.form.sessionRating <  1) {
                    t.errors.sessionRating = true
                    t.errors.sessionRatingErrorMessage = 'Session Rating required'
                }
                // Feeling
                if (t.form.sessionFeeling == '') {
                    t.errors.sessionFeeling = true
                    t.errors.sessionFeelingErrorMessage = 'Session Feeling required'
                    this.MIX_alertBox({ color: "error", timeout: 3000, message: "Select a Feeling !", show: true });

                }
                // Check if there any errors left
                if (!Object.values(t.errors).includes(true)) {
                    return true
                }
            },

            /**
             * Upadte session
             */
            async updateSession(){
                if( this.validateForm()){
                    this.commentForm.created_at = this.$moment().format("X");
                    this.commentForm.user = this.currUserData.userName;
                    this.form.sessionComments[0]= this.commentForm
                    const updateDocumentResult = await this.MIX_firestore_updateMultipleFields(this.form.id, 'sessions', this.form);
                    if (updateDocumentResult.code === 1) {
                        this.MIX_alertBox({ color: "success", timeout: 3000, message: "Session Updated", show: true });
                        this.$emit('emitCloseRightPanel')
                    }else{
                        this.MIX_alertBox({ color: "error", timeout: 3000, message: "Session Failed To Be Updated", show: true });
                    }
                }
            },

            /**
             * Validated all the fields and add the document to the collection
             */
            async saveSession(){
                if( this.validateForm()){
                    this.form.userId = this.GET_FIREBASE_userAuth.uid;
                    this.form.userName = this.currUserData.userName;
                    this.commentForm.user = this.currUserData.userName;
                    this.commentForm.created_at = this.$moment().format("X");
                    this.form.sessionCreatedAt = this.$moment().format("X");
                    this.form.sessionComments.push(this.commentForm);
                    this.form.currentPosition = this.currUserData.userPlayerPosition;
					await this.MIX_firestore_update(this.GET_FIREBASE_userAuth.uid,"users", "sessionsNo",  this.currUserData.sessionsNo + 1)
                    await this.MIX_firestore_create_autoId( "sessions", this.form);			
                    this.MIX_alertBox({ color: "success", timeout: 3000, message: "Your session was submitted !", show: true });
                    this.$emit('emitCloseRightPanel')
                }
            },

            /**
             * This method detects which icon is active and return the coresponding class
             * @param {*} Feeling Takes the feeling by user selection
             * @returns the class of the feeling
             */
            returnActiveIcon(feeling){
                if(this.form.sessionFeeling===feeling) {
                   switch(feeling) {
                        case 'sad':
                            return  "red--text";
                        case 'neutral':
                            return  "neutral--text";
                        case 'happy':
                            return  "update--text";
                        default:
                            return  "grey--text";
                        }
                } else {
                    return  "grey--text";
                }   
            },
            
            async findCoaches(){
                let coach = await this.MIX_firestore_readManyWhereCondition("users", "userLevel", "==", "Coach-User")
                this.coachArray = coach;
                this.coachArray = this.$sortBy(this.coachArray, 'userName');
            }
        },


        watch:{
            dateMenu() {
                this.form.sessionDate = this.$moment(this.date, "YYYY-MM-DD").format("X");
            },
        },

        async mounted() {
            this.form.sessionDate = this.$moment(this.date, "YYYY-MM-DD").format("X");
        },

        async created(){
            // Find out user data
            let currUser = await this.MIX_firestore_read(this.GET_FIREBASE_userAuth.uid, "users");
            this.currUserData = currUser.data;

            this.skillsArray = await this.MIX_firestore_readManyWhereCondition("skills", "fieldPosition", "array-contains", this.currUserData.userForwardBack);
            this.findCoaches();
            this.isPageLoading= false;
        }
    }
</script>

<style scoped>
    .full-width {
        width: 100% !important;
        }
    .feeling {
        font-size: 80px !important;
    }
</style>
