<template>
    <v-row class="d-flex justify-center" no-gutters>
        <v-col cols="12" xs="12" sm="12" md="10" lg="8" class="">

            <!-- Logo -->
            <v-row class="pt-4 px-5 justify-center">
                <v-col cols="12" md="12">
                    <v-img class="rounded-pill" src="@/assets/logo.png" style="text-align:center ; margin:auto" contain width="256" height="auto"></v-img>
                </v-col>
            </v-row>

            <!--Log In -->
            <v-row class="d-flex justify-center mt-1">
                <v-col cols="12" xs="12" sm="6" class="px-6">
                    <div class="justify-center" style="width: 100% !important;">

                        <!--Email and Password Validation-->
                        <v-row class="mt-2" no-gutters>
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"> 
                                <div class="text-h6 font-weight-normal primary--text">Login</div> 
                                    <v-form ref="loginForm" v-on:submit.prevent="login()" class="my-4" v-model="valid" lazy-validation>
                                        <v-text-field dense class="mb-2" v-model.trim="userEmail" outlined :background-color="inputBackgroundColor" label="Email Address" 
                                            append-icon="icons8-envelope" 
                                            hide-details="auto" 
                                            :rules="rules.emailRules"
                                            required>
                                        </v-text-field>
                                        <v-text-field dense class="mb-2" v-model.trim="userPassword" outlined :background-color="inputBackgroundColor" label="Password" 
                                            :append-icon="show ? 'icons8-eye' : 'icons8-invisible'" 
                                            :type="show ? 'text' : 'password'" 
                                            @click:append="show = !show" 
                                            :rules="rules.passwordRules"
                                            hide-details="auto"
                                            required
                                            v-on:keyup.enter="login()"
                                        ></v-text-field>
                                    </v-form>
                            </v-col>
                        </v-row>

                        <!--Log In Buttons-->
                        <v-row class="my-4">
                            <v-col cols="12">
                                <v-btn block depressed :loading="loading" class="primary" large @click.native="login()">Log In</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>

            <v-row class="d-flex justify-center text-center mt-2 px-5">
                <v-col cols="12" xs="12" sm="6">

                    <!--Reset Password-->
                    <p>Forgot Password? <router-link :to="{ path: 'reset-password' }" replace class="btn btn-link" >Reset password</router-link></p>    
                    <v-divider class="primary-lighten-2 pb-4"></v-divider>

                    <!--Register -->
                    <div>Don't have an account?</div>
                    <v-btn :to="{ path: 'register' }" replace class="primary elevation-0 mt-4 mb-4">Register</v-btn>
                    <br/>
                                   
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script> 

    import { mapActions } from 'vuex'

    export default {
        name: 'Login',

        data: () => ({
            inputBackgroundColor: 'white',
            valid: true,
            show: false,
            userEmail: '',
            userPassword: '',
            loading: false, 
            rules: [],
        }),
        methods: {
            ...mapActions({ ACT_FIREBASE_userAuth: 'firebase_auth_store/ACT_FIREBASE_userAuth' }),

            //* CHECKING USER IS ON DATABASE*/
            async checkuserAuth(userID){
                let t = this;
                let userDataResult = await t.MIX_firestore_read(userID, 'users');
                if (userDataResult.code === 1) {
                    return userDataResult
                } else {
                    t.MIX_alertBox({ color: 'error', timeout: 2000, message: 'Account not found', show: true })
                    return userDataResult
                }
            },

            //* LOGIN
            login() {
                let t = this;
                // t.MIX_progressBar({show: true});
                t.userEmail = t.userEmail.toLowerCase();
                t.rules = {
                    emailRules: [(v) => !!v  || 'E-mail is required', (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid' ,],
                    passwordRules: [(v) => !!v || 'Password is required']
                }
                t.$nextTick(() => {
                    if (t.$refs.loginForm.validate()) {
                        t.$firebase.auth
                            .signInWithEmailAndPassword(t.userEmail, t.userPassword)
                            .then(async(data) => {
                                var checkUserResult = await t.checkuserAuth(data.user.uid);
                                if (checkUserResult.code === 1){
                                    await t.MIX_FIREBASE_userAuth(data.user)
                                }
                            })
                            .then(() => {
                                t.$router.push("/").catch(error => { console.log(error.message)} )
                            })
                            .catch((err) => {
                                t.MIX_alertBox({ color: 'error', timeout: 3000, message: err, show: true })
                            });
                    } else {
                        t.MIX_alertBox({ color: 'error', timeout: 3000, message: 'The email password combination is incorrect.', show: true })
                    }
                })
            }
        }
    }
</script>