<template>
	<v-banner app sticky class="secondary d-flex align-end" fluid style="border-bottom: 4px solid var(--v-primary-base) !important; padding: 0 !important; height: 85px !important; z-index: 1000 !important">
		<v-tabs v-model="tabs" hide-slider fixed-tabs icons-and-text background-color="transparent" color="primary">
			
			<!--Players-Tabs-->
			<v-tab v-if="['Player-User'].includes(userDataResult.userLevel)" href="#mobile-tabs-5-1" class="" to="/sessions"> Sessions<v-icon large>icons8-stopwatch-2</v-icon> </v-tab>
			<v-tab v-if="['Player-User'].includes(userDataResult.userLevel)" href="#mobile-tabs-5-1" class="" to="/matches"> Matches<v-icon large>icons8-rugby-3</v-icon> </v-tab>
			<v-tab v-if="['Player-User'].includes(userDataResult.userLevel)" href="#mobile-tabs-5-1"  class="" to="/idp"> IDP<v-icon large>icons8-grid-2</v-icon> </v-tab>
			
			<!--Coach and Admin tabs-->
			<v-tab v-if="['Staff-Admin', 'Coach-User'].includes(userDataResult.userLevel)" href="#mobile-tabs-5-1" class="" to="/user-directory"> Users<v-icon large>icons8-user-2</v-icon> </v-tab>
			<v-tab v-if="['Staff-Admin', 'Coach-User'].includes(userDataResult.userLevel)" href="#mobile-tabs-5-1" class="" to="/squad"> Squad<v-icon large>icons8-team</v-icon> </v-tab>
		
		</v-tabs>
	</v-banner>
</template>

<script>

	import { mapGetters } from 'vuex';

	export default {
		name: "App",
		data:() => ({
			tabs: 0,
			userDataResult:'',
		}),

		computed: {
			...mapGetters({
				GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
			}),
		},

		async created(){
			let userDataResult1 = await this.MIX_firestore_read(this.GET_FIREBASE_userAuth.uid, 'users');
			this.userDataResult = userDataResult1.data;
		}
	}
</script>
