<template>
	<v-row class="d-flex justify-center" no-gutters>
        <v-col cols="12" lg="5" class="">
            <v-row class="pt-4 px-5 text-center justify-center">
                <v-col cols="12" md="12">
                    <v-img class="rounded-pill mb-4" src="@/assets/logo.png" style="text-align:center ; margin:auto" contain width="256" height="auto"></v-img>
						<span class="text-h6">Welcome to <span class="primary--text">{{ config.organisation }}</span> {{ config.appname }}</span><br /><br />
						<span v-if="userStatus === 'Pending'">Your account status is currently <span class="warning--text font-weight-bold">PENDING</span>, please wait for your account to be Approved. <br /><br />If you are waiting more than 48 hours for your account to be approved please contact <a :href="'mailto:' + config.adminEmail" class="font-weight-bold">{{ config.adminEmail }}</a></span>
						<span v-if="userStatus === 'Approved'">Your account has now been <span class="success--text font-weight-bold">APPROVED</span>, please click the button to continue.</span>
						<span v-if="userStatus === 'Suspended'">Your account has been <span class="warning--text font-weight-bold">SUSPENDED</span>, if you believe this is an error then contact <a :href="'mailto:' + config.adminEmail" class="font-weight-bold">{{ config.adminEmail }}</a></span>
						<span v-if="userStatus === 'Deleted'">Your account has now been <span class="error--text font-weight-bold">DELETED</span>, if you believe this is an error then contact <a :href="'mailto:' + config.adminEmail" class="font-weight-bold">{{ config.adminEmail }}</a></span>
						<span v-if="userStatus === 'Rejected'">Your account has been <span class="error--text font-weight-bold">REJECTED</span>, if you believe this is an error then contact <a :href="'mailto:' + config.adminEmail" class="font-weight-bold">{{ config.adminEmail }}</a></span>
						<span v-if="userStatus === 'Archived'">Your account has been <span class="primary--text font-weight-bold">ARCHIVED</span>, if you believe this is an error then contact <a :href="'mailto:' + config.adminEmail" class="font-weight-bold">{{ config.adminEmail }}</a></span>
						<div class="mt-5 align-center"><v-btn depressed class="success" to='/' v-if="userStatus === 'Approved'">Home</v-btn></div>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
	/* eslint-disable */
	import config from '@/config.js';
	import { doc, onSnapshot } from "firebase/firestore";
	import { mapGetters } from 'vuex';

	export default {
		data:() => ({
			userStatus: null,
		}),
		computed: {
			...mapGetters({ 
				GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
			}),
			config() {
				return config;
			}
		},
		mounted(){
			let userDataResult = "";
			// When the userStatus will change by an admin the snapshot will fire give the according permission to the current user
			onSnapshot(doc(this.$firebase.db, 'users', this.GET_FIREBASE_userAuth.uid), (doc) => {
				userDataResult = doc.data();
				this.userStatus = userDataResult.userStatus;
			});
		},
	}
</script>