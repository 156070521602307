/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Sessions from '@/views/sessions/sessions.vue'
//import Organisations from '@/views/organisations/organisationsList.vue';
import IDP from '@/views/idp/IDP.vue';
import UserProfile from "@/views/userProfile/userProfile.vue";
import Matches from "@/views/matches/matches.vue";
import Squad from '@/views/squad/squad.vue';
// auth routes
import Register from "@/views/auth//register.vue";
import Login from "@/views/auth/login.vue";
import ResetPassword from "@/views/auth/reset-password.vue";
import Details from "@/views/auth/details.vue";
import UserDirectory from "@/views/users/user-directory.vue";
import Holding from "@/views/auth/holding.vue";

import { getAuth, onAuthStateChanged } from "firebase/auth";

// store
import store from "@/store.js";

Vue.use(VueRouter)

const routes = [
	{ path: "/", name: "Home", component: Home, meta: {hideNavbar: false, requiresAuth: true, userLevel: ['Staff-Admin','Coach-User','Player-user'], userStatus: 'Approved' } },
	{ path: "/idp", name: "IDP", component: IDP, meta: {hideNavbar: false, requiresAuth: true, userLevel: ['Player-user'], userStatus: 'Approved' } },
	{ path: '/sessions', name: 'Sessions', component: Sessions, meta: {hideNavbar: false, requiresAuth: true, userLevel: ['Player-user'], userStatus: 'Approved' } },
	{ path: '/user-directory', name: 'UserDirectory', component: UserDirectory, meta: {hideNavbar: false, requiresAuth: true,  userLevel: ['Staff-Admin', 'Coach-User'], userStatus: 'Approved' } },
    { path: '/userProfile', name: 'UserProfile', component: UserProfile, meta: {hideNavbar: false, requiresAuth: true,  userLevel: ['Staff-Admin','Coach-User','Player-user'], userStatus: 'Approved' } },
    { path: '/matches', name: 'Matches', component: Matches, meta: {hideNavbar: false, requiresAuth: true, userLevel: ['Player-user','Coach-User'], userStatus: 'Approved' } },
    { path: '/squad', name: 'Squad', component: Squad, meta: {hideNavbar: false, requiresAuth: true, userLevel: ['Coach-User'], userStatus: 'Approved' } },


    // *  AUTH ROUTES
    // ? register for normal user
    { path: "/details", name: "Details", component: Details },
    { path: "/register/:id?", name: "Register", component: Register, meta: { hideNavbar: true, requiresAuth: false } },
    { path: "/login", name: "Login", component: Login, meta: { hideNavbar: true, requiresAuth: false } },
    { path: "/reset-password", name: "ResetPassword", component: ResetPassword, meta: { hideNavbar: true, requiresAuth: false } },
    { path: "/holding", name: "Holding", component: Holding, meta: { hideNavbar: true, requiresAuth: false } },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
    
    if (to.meta.requiresAuth) {

       
        const auth = getAuth();
        await onAuthStateChanged(auth, async (user) => {
            if(user !=null){
                user.getIdToken(true).then((idToken) => {
                    var token = JSON.parse(new Buffer(idToken.split('.')[1], 'base64').toString('utf8'));
    
                    if (token.user_status === 'Approved') {
                        next();
                    } else {
                        next('/holding');
                    }
                })
            }else{
                next('/login')
            }
          
        })
    } else {
        next();
    }
})

export default router