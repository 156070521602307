<template>
    <div class="pa-4">
        {{computedFormData}}

        <pageLoader :is-page-loading="isPageLoading"/>

        <!--Form-->
        <div class="d-flex align-center">
            <v-form v-model="valid" lazy-validation style="width:100%;">

                <!-- Date Selection-->
                <div>
                    <app-text category="text-medium" class="grey--text">Date Of Match</app-text>                                                                      
                    <v-menu v-model="dateMenu" :rules="matchDateRule" :close-on-content-click="false" transition="scale-transition" offset-y 
                        max-width="290px" 
                        min-width="auto" 
                        required>
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field :disabled="computedIsFormReadOnly" dense background-color="white" outlined v-model="computedDate" 
                                hide-details="auto" 
                                append-icon="mdi-calendar" 
                                class="rounded-lg mt-2"
                                height="48px"
                                flat
                                :error="errors.date"
                                :error-messages="errors.dateErrorMessage"
                                filled
                                readonly 
                                v-bind="attrs"
                                v-on="on">
                            </v-text-field>
                        </template>
                        <v-date-picker v-model="date" no-title @input="dateMenu = false" :max="disableFutureDate" scrollable ></v-date-picker>
                    </v-menu>
                </div>

                <!--Opponent-->
                <div class="mt-8">
                    <app-text category="text-medium" class="grey--text">Opponent</app-text>
                    <app-input input-type="textInput"
                        :disabled="computedIsFormReadOnly"                                            
                        class="mt-2"
                        :error="errors.matchOpponent"
                        :error-messages="errors.matchOpponentErrorMessage"
                        hide-details="auto" 
                        v-model="form.matchOpponent"/>
                </div>

                <!--Did you start-->                
                <div class="mt-8">
                    <app-text category="text-medium" class="grey--text">Did you start?</app-text>
                    <v-radio-group :readonly="computedIsFormReadOnly" class="" v-model="form.matchStart" row required> 
                        <v-radio label="YES"  value="YES"></v-radio>
                        <v-radio label="NO" value="NO"></v-radio>
                    </v-radio-group>
                </div>

                <!--Player Position-->
                <div class="mt-2">
                    <app-text category="text-medium" class="grey--text">Position</app-text>
                    <app-input input-type="comboBox"
                        :disabled="computedIsFormReadOnly"                                            
                        class="mt-2"
                        hide-details="auto"
                        :error="errors.matchPlayerPostition"
                        :error-messages="errors.matchPlayerPostitionErrorMessage"
                        :items="readPositionAndSort"
                        item-text="name"
                        item-value="name"
                        :returnObject="false"
                        appendIcon="icons8-calendar-2"
                        v-model="form.matchPlayerPostition"/>                       
                </div>
 
                <!--Fatigue level-->
                <div class="mt-8">
                    <app-text category="text-medium" class="grey--text">How were your Fatigue Levels?</app-text>
                </div>
                <!--Fatigue level Readonly -->
                <div v-if="computedIsFormReadOnly" class="d-flex mt-2">
                    <app-text category="text-small" class=" grey--text ml-2">Poor</app-text>
                    <div class="ml-2 " style="width:100%;" v-for="i in 5" :key="i">
                        <v-btn 
                            :class="form.matchFatigue=== i ? 'white--text': 'black--text'"
                            fab
                            light
                            elevation="0"
                            small
                            :color="form.matchFatigue=== i? 'primary': 'white'">
                            {{i}}
                        </v-btn>
                    </div>
                    <app-text category="text-small" class="mr-2 grey--text">Great</app-text>
                </div>
                <!--Fatigue level Editable-->
                <div v-else class="d-flex mt-2"  >
                    <app-text category="text-small" class="ml-2 grey--text">Poor</app-text>
                    <div class="ml-2" style="width:100%;" v-for="i in 5" :key="i">
                        <v-btn 
                            @click="form.matchFatigue = i" 
                            :class="form.matchFatigue=== i ? 'white--text': 'black--text'"
                            fab
                            elevation="0"
                            light
                            small
                            :color="form.matchFatigue=== i? 'primary': 'white'"> 
                            {{i}}
                        </v-btn>
                    </div>
                    <app-text category="text-small" class="mr-2 grey--text">Great</app-text>
                </div>

                <!--Personal Points-->
                <div class="mt-8">
                    <app-text category="text-medium" class=" grey--text">Personal points from the match</app-text>
                    <app-input input-type="textArea"
                        :disabled="computedIsFormReadOnly"                                            
                        class="mt-2"
                        :error="errors.matchComments"
                        :error-messages="errors.matchCommentsErrorMessage"
                        hide-details="auto" 
                        v-model="matchForm.content"
                        :rows="4"/>
                </div>

                <!--Match Performace-->
                <div class="mt-8">
                    <app-text :disabled="computedIsFormReadOnly" category="text-medium" class="grey--text">Rate your performance</app-text>
                </div>
                <!--ReadOnly Match Performance-->
                <div v-if="computedIsFormReadOnly" class="d-flex mt-2">
                    <app-text category="text-small" class="ml-2 grey--text">Poor</app-text>
                    <div class="ml-2" style="width:100%;" v-for="i in 5" :key="i">
                        <v-btn   
                            :class="form.matchPerformance=== i ? 'white--text': 'black--text'"
                            fab
                            elevation ="0"
                            light
                            small
                            :color="form.matchPerformance=== i? 'primary': 'white'">
                            {{i}}
                        </v-btn>
                    </div>
                    <app-text category="text-small" class="mr-2 grey--text">Great</app-text>
                </div>
                <!--Editable Match Performance-->
                <div v-else class="d-flex mt-2"  >
                    <app-text category="text-small" class="ml-2 grey--text">Poor</app-text>
                    <div class="ml-2" style="width:100%;" v-for="i in 5" :key="i">
                        <v-btn 
                            @click="form.matchPerformance = i" 
                            :class="form.matchPerformance=== i ? 'white--text': 'black--text'"
                            fab
                            elevation ="0"
                            light
                            small
                            :color="form.matchPerformance=== i? 'primary': 'white'">
                            {{i}}
                        </v-btn>
                    </div>
                    <app-text category="text-small" class="mr-2 grey--text">Great</app-text>
                </div>
                    
                <!--Feelings-->
                <div class="mt-8">
                    <app-text category="text-medium" class="grey--text">How you feeling today?</app-text>                              
                    <!--ReadOnly Feeling-->
                    <div v-if="computedIsFormReadOnly" class="d-flex  align-center  justify-center ">
                        <div v-for="(item,index) in feelingArray" :key="index">
                            <v-icon v-model="form.matchFeeling" :class="returnActiveIcon(item.text)" class="feeling" >{{item.icon}}</v-icon>
                        </div>
                    </div>
                    <!--Editable Feelings-->
                    <div v-else  class="d-flex  align-center  justify-center  ">
                        <div  v-for="(item,index) in feelingArray" :key="index">
                            <v-icon v-model="form.matchFeeling" :class="returnActiveIcon(item.text)" class="feeling" 
                                    @click="form.matchFeeling=item.text" >{{item.icon}} </v-icon>
                        </div>
                    </div>
                </div> 
            </v-form>
        </div>

        <!--Buttons-->
        <div class="d-flex mt-8">
            <v-col cols="6">
                <app-btn v-if="!computedIsFormReadOnly" @click.native="$emit('emitCloseRightPanel')" block depressed large color="grey lighten-2" 
                    icon="icons8-cancel"
                    label="CANCEL"/>
            </v-col>
            <v-col cols="6">
                <app-btn v-if="!computedIsFormReadOnly && formData != ''" block depressed large @click.native="updateMatch" color="success"
                    icon="icons8-save"
                    label="SAVE"/>
                <app-btn v-else-if="!computedIsFormReadOnly" @click.native="saveMatch" block depressed large color="success" 
                    icon="icons8-save"
                    label="SAVE"/>
            </v-col>
        </div>
    </div>
</template>

<script>
    import {mapGetters, } from "vuex";

    export default {
    
        name: "matchForm",
        props: ['doesFormStartReadOnly', 'formData'],

        data: (vm) => ({
            
            // Feelings
            feelingArray: [ {text:'sad', icon:'icons8-sad'},
                            {text:'neutral', icon:'icons8-neutral'},
                            {text:'happy', icon:'icons8-happy'}],

            //Date
            date: vm.$moment().format("YYYY-MM-DD"),

            //Flags
            isPageLoading:true, 
			dateMenu: false,
			isFormReadOnly: false,

            currUserData : "",

            errors: {
                matchDate:false,
                matchDateErrorMessage:"",
                matchOpponent:false,
                matchOpponentErrorMessage:"",
                matchStart:false,
                matchStartErrorMessage:"",
                matchPlayerPostition:false,
                matchPlayerPostitionErrorMessage:"",
                matchFatigue:false,
                matchfatigueErrorMessage:"",
                matchComments:false,
                matchCommentsErrorMessage:"",
                matchPerformance:false,
                matchPerformanceErrorMessage:"",
                matchFeeling:false,
                matchFeelingErrorMessage:"",
            },

            // User Match Review Form
			form: {
				id:"",
				userId: "",
				matchStart:"",
				matchDate: "",
				matchComments: [],
				matchFeeling: "",
				matchCreatedAt: "",
                matchPlayerPostition:"",
                matchOpponent:"",
                matchPerformance:1,
                matchFatigue:1,
			},

            //Reference for match feedback chat
			matchForm: {
				content: "",
				user: "",
				created_at: ""
			},

            // Rules
			valid: true,
            matchOpponentRule:  [(v) => !!v || "Match opponent is required"],
            matchPositionRule: [(v) => !!v || "Match position is required"],
			matchDateRule: [(v) => !!v || "Match date is required"],
			matchCommentsRule: [(v) => !!v || "Match comments are required"],
        }),

        computed: {
            ...mapGetters({ 
                GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
            }),

            /**
             * Computed From Data
             *
             * If this is an existing document, set the form data.
             * If it's not, the default form will be used.
             */
            computedFormData() {
                const formData = this.$props.formData
                if (formData?.id) {
                    // Assign data
                    this.form = formData
                    this.matchForm.content =  this.form.matchComments[0].content;
                    this.date = this.$moment(this.form.matchDate, "X").format("YYYY-MM-DD")
                }
            },

            /**
             * Computes the date
             */
            computedDate() {
                return this.$moment(this.date, "YYYY-MM-DD").format("DD-MMM-YYYY");
            },

            /**
             * Computed Is Form Read Only
             *
             * When the form is first created in the Supervisor's Control Panel,
             * the form should be editable.
             * If the form is open through the Reporting pages, it should open uneditable.
             *
             * @returns {boolean} is form read only
             */
            computedIsFormReadOnly() {
                return this.$props.doesFormStartReadOnly
                    ? this.$props.doesFormStartReadOnly
                    : this.isFormReadOnly
            },

            /**
             * This method return a sort array
             * @returns The position array sorted.
             */
            readPositionAndSort(){
               return this.playersPositions.sort()
            },
            
            /**This method disable the future dates */
            disableFutureDate(){
                return new Date().toISOString().substr(0, 10);
            }

        },
        methods: {

            /**
             * Validation of the form and the updated 
             * of the necessary error field with the respected message
             */
            validateForm() {
                const t = this
                t.errors.matchDate = false
                t.errors.matchDateErrorMessage = ''
                t.errors.matchOpponent = false
                t.errors.matchOpponentErrorMessage = ''
                t.errors.matchPlayerPostition = false
                t.errors.matchPlayerPostitionErrorMessage = ''
                t.errors.matchComments = false
                t.errors.matchCommentsErrorMessage = ''
                t.errors.matchPerformance = false
                t.errors.matchPerformanceErrorMessage =''
                t.errors.matchFeeling = false,
                t.errors.matchFeelingErrorMessage = ''
                // Date
                if (!t.form.matchDate.trim()) {
                    t.errors.matchDate = true
                    t.errors.matchDateErrorMessage = 'Match Date required'
                }
                // Duration
                else if (!t.form.matchOpponent.trim() ) {
                    t.errors.matchOpponent = true
                    t.errors.matchOpponentErrorMessage = 'Match Opponent required'
                }
                // Skill 
                if (!t.form.matchPlayerPostition.trim()) {
                    t.errors.matchPlayerPostition = true
                    t.errors.matchPlayerPostitionErrorMessage = 'Match Skill required'
                }
                // Comment
                if (t.matchForm.content == '') {
                    t.errors.matchComments = true
                    t.errors.matchCommentsErrorMessage = 'match Comment required'
                }
                // rating
                if (t.form.matchPerformance <  1) {
                    t.errors.matchPerformance = true
                    t.errors.matchPerformanceErrorMessage = 'match Rating required'
                }
                // Feeling
                if (t.form.matchFeeling == '') {
                    t.errors.matchFeeling = true
                    t.errors.matchFeelingErrorMessage = 'match Feeling required'
                    this.MIX_alertBox({ color: "error", timeout: 3000, message: "Select a Feeling !", show: true });
                }
                // Check if there any errors left
                if (!Object.values(t.errors).includes(true)) {
                    return true
                }
            },

            /**
             * Updated the the match
             */
            async updateMatch(){
                if(this.validateForm()){
                    this.matchForm.created_at = this.$moment().format("X");
                    this.matchForm.user = this.currUserData;
                    this.form.matchComments[0]= this.matchForm
                    const updateDocumentResult = await this.MIX_firestore_updateMultipleFields(this.form.id, 'matchReviews', this.form);
                    if (updateDocumentResult.code === 1) {
                        this.MIX_alertBox({ color: "success", timeout: 3000, message: "Match Updated", show: true });
                        this.$emit('emitCloseRightPanel')
                    }else{
                        this.MIX_alertBox({ color: "error", timeout: 3000, message: "Match failed To Be Updated", show: true });
                    }
                }
            },

            /**
             * Validated all the fields and add the document to the collection
             */
            async saveMatch(){
                if(this.validateForm()){
                    this.form.userId = this.GET_FIREBASE_userAuth.uid;
                    this.form.userName = this.currUserData.userName;
                    this.matchForm.user = this.currUserData.userName;
                    this.matchForm.created_at = this.$moment().format("X");
                    this.form.matchCreatedAt = this.$moment().format("X");
                    this.form.matchComments.push(this.matchForm);
                    await this.MIX_firestore_update(this.GET_FIREBASE_userAuth.uid,"users", "matchesNo",  this.currUserData.matchesNo + 1)
                    await this.MIX_firestore_create_autoId( "matchReviews", this.form);
                    this.MIX_alertBox({ color: "success", timeout: 3000, message: "Your match was submitted !", show: true });
                    this.$emit('emitCloseRightPanel')
                }
            },

            /**
             * This method detects which icon is active and return the coresponding class
             * @param {*} Feeling Takes the feeling by user selection
             * @returns the class of the feeling
             */
            returnActiveIcon(feeling){
                if(this.form.matchFeeling===feeling) {
                   switch(feeling) {
                        case 'sad':
                            return  "red--text";
                        case 'neutral':
                            return  "neutral--text";
                        case 'happy':
                            return  "update--text";
                        default:
                            return  "grey--text";
                        }
                } else {
                    return  "grey--text";
                } 
            },            
        },

        watch:{
            dateMenu() {
                this.form.matchDate = this.$moment(this.date, "YYYY-MM-DD").format("X");
            },
        },

        async mounted() {
            this.form.matchDate = this.$moment(this.date, "YYYY-MM-DD").format("X");
        },
        
        async created(){
            // Find out user data
            let currUser = await this.MIX_firestore_read(this.GET_FIREBASE_userAuth.uid, "users");
            this.currUserData = currUser.data;
            this.isPageLoading= false;
        }
    }
</script>

<style scoped>
    .full-width {
        width: 100% !important;
    }
    .feeling {
        font-size: 80px !important;
    }
</style>
