import Vue from "vue";

/* mixin */
const sw_mixin = {
    data() {
        return {
            offline: false,
            refreshing: false,
            registration: null,
            updateExists: false,
        }
    },
    created() {
        document.addEventListener('swUpdated', this.updateAvailable, { once: true })
        // This prevents Multiple Refreshes
        navigator.serviceWorker.addEventListener('controllerchange', () => {
            // We'll also need to add 'refreshing' to our data originally set to false.
            if (this.refreshing) return
            this.refreshing = true
            // Here the actual reload of the page occurs
            window.location.reload() // auto refreshing
        })
    },
    methods: {
        start() {
            //window.location.reload()
        },
        // Store the SW registration so we can send it a message
        // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
        // To alert the user there is an update they need to refresh for
        updateAvailable(event) {
            this.registration = event.detail
            this.updateExists = true
        },
        refreshApp() {
            this.updateExists = false
            // Make sure we only send a 'skip waiting' message if the SW is waiting
            if (!this.registration || !this.registration.waiting) return
            // Send message to SW to skip the waiting and activate the new SW
            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
        }
    }
}

export default {
    install (Vue, options) {
      Vue.mixin(sw_mixin)
    }
  }
