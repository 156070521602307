<template>
	<v-row class="d-flex justify-center" no-gutters>
        <v-col cols="12" xs="12" sm="12" md="10" lg="8" class="">

			<!-- Logo and Page Title-->
			<v-row class="pt-4 px-4 justify-center">
				<v-col cols="12" xs="12" sm="6">
					<v-img class="rounded-pill mb-4" src="@/assets/logo.png" style="text-align:center ; margin:auto" contain width="256" height="auto"></v-img>
					<div class="text-h6 font-weight-normal primary--text">Registration</div>
					<p class="mt-4 mb-2 text-left body2">Please use the form below to register as a user.</p>
					<v-divider></v-divider>
				</v-col>
			</v-row>

			<!--Registration Steps-->
			<v-row class="d-flex justify-center mt-2">
				<v-col cols="12" xs="12" sm="6" class="px-6 pb-0">
					<v-progress-linear v-if="loading === true" indeterminate color="primary"></v-progress-linear>
					<v-row class="mt-0 justify-center">
						<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
							<v-window v-model="step">

								<!-- STEP 1 - CONTACT DETAILS -->
								<v-window-item :value="1">
									<v-form ref="registerUser" v-model="valid" lazy-validation>
										<v-text-field outlined background-color="white" class="mt-2 mb-2" v-model="form.userName" label="Full Name" hide-details="auto" :rules="rules1.nameRules" required></v-text-field>
										<v-text-field outlined background-color="white" class="mb-2" v-model="form.userEmail" label="Email Address" hide-details="auto" :rules="rules1.emailRules" required></v-text-field>
										<v-select outlined background-color="white" class="mb-2" v-model="form.userForwardBack" label="Forward or Back" hide-details="auto" :items="sortForwardBack" :rules="rules1.requiredField" required></v-select>
										<v-select v-if="form.userForwardBack != '' && form.userForwardBack != 'N/A'" 
											outlined background-color="white" class="mb-2" v-model="form.userPlayerPosition" 
											label="Player Position" hide-details="auto" :items="sortPositions" :rules="rules1.requiredField" required></v-select>
										<!-- <v-checkbox color="primary" v-model="form.privacyPolicyConsent" hide-details :rules="rules1.privacyRules" required class="d-flex align-start" style="position: relative !important; top: 10px !important">
											<template v-slot:label>
												<div>
													<span class="font-weight-medium"> I have completed this form as accurately as possible and Accept the <span class="primary--text font-weight-bold" @click="privacyDialog = true">Terms & Conditions</span> </span>
												</div>
											</template>
										</v-checkbox> -->
									</v-form>
									<!-- Next Window Button-->
									<v-row class="my-4">
										<v-col cols="12">
											<v-btn block depressed @click="validateDetails" :loading="loading1" :disabled="loading1" large class="mt-4 primary">Next</v-btn>
										</v-col>
									</v-row>
								</v-window-item>

								<!-- STEP 2 - PASSWORD-WINDOW-->
								<v-window-item :value="2">
									<v-form ref="userPassword" v-model="valid1" lazy-validation>
										<v-text-field v-model.trim="password" filled background-color="white" class="mb-2" hide-details="auto" label="Password" :append-icon="showPasswordIcon ?
											'icons8-eye' : 'icons8-invisible'" 
											:type="showPasswordIcon ? 'text' : 'password'" 
											@click:append="showPasswordIcon = !showPasswordIcon" 
											:rules="rules2.passwordRules"
											required>
										</v-text-field>
										<v-text-field v-model.trim="confirmPassword" filled background-color="white" class="mb-2" hide-details="auto" label="Confirm Password" 
											:append-icon="showPasswordIcon1 ? 'icons8-eye' : 'icons8-invisible'" 
											:type="showPasswordIcon1 ? 'text' : 'password'" 
											@click:append="showPasswordIcon1 = !showPasswordIcon1" 
											:rules="rules2.confirmRules" 
											required>
										</v-text-field>
									</v-form>
									<!--Register button -->
									<v-row class="my-4">
										<v-col cols="6">
											<v-btn block depressed large @click="step--" class="mt-3 grey lighten-2">Back</v-btn>
										</v-col>
										<v-spacer></v-spacer>
										<v-col cols="6">
											<v-btn block depressed large @click="validatePassword" :loading="loading" :disabled="loading" class="mt-3 primary">Register</v-btn>
										</v-col>
									</v-row>
								</v-window-item>

								<!-- Error message -->
								<v-window-item :value="3">
									<div class="pa-4 text-center" v-if="accountCreated === true">
										<v-progress-linear indeterminate color="primary"></v-progress-linear>
										<h3 class="title font-weight-light mb-2">Welcome</h3>
										<span class="caption grey--text">Thanks for signing up! You will be redirected to the home page</span>
									</div>
									<div class="pa-4 text-center" v-else>
										<h3 class="title font-weight-light mb-2">Something went wrong</h3>
										<h4 v-if="registerErrorMessage != null">{{ registerErrorMessage }}</h4>
										<span class="caption grey--text">Try again!</span>
									</div>
								</v-window-item>
							</v-window>
						</v-col>
					</v-row>

					<!-- end of USER-INPUT -->
					<v-dialog style="z-index: 3000 !important" v-model="privacyDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
						<v-card class="background">
							<v-toolbar dark color="primary heading5">
								Privacy and Terms & Conditions
								<v-spacer></v-spacer>
								<v-icon  
								@click="privacyDialog = false">mdi-close</v-icon>
							</v-toolbar>
							<!-- <PrivacyTermsConditions /> -->
							<v-divider></v-divider>
						</v-card>
					</v-dialog>
				</v-col>
			</v-row>

			<v-row class="d-flex justify-center text-center mt-1 mb-4">
				<v-col cols="12">
					<router-link :to="{ path: 'login' }" replace class="btn btn-link">Return to Login</router-link>
				</v-col>
			</v-row>

		</v-col>
	</v-row>
</template>

<script>
	/* eslint-disable */
	import { getAuth, updateProfile, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";

	export default {
		name: "Register",

		data() {
			return {
				step: 1,
				valid: true,
				valid1: true,

				//Password icon
				showPasswordIcon: false,
				showPasswordIcon1: false,

				// user form
				form: {
					userSportType:"Rugby",
					id: "",
					userName: "",
					userAccessAndAbilities:"",
					userEmail: "",
					userTelephone: "",
					userForwardBack:"",
					userPlayerPosition:"",
					userProfile:"",
					createdDateTime: "",
					createdUserId: "",
					createdUserName: "",
					createdUserEmail: "",
					modifiedDateTime: "",
					modifiedUserId: "",
					modifiedUserName: "",
					modifiedUserEmail: "",
					idpCompleted:false,
					sessionsNo :0,
					matchesNo: 0,
				},

				password: "", // password input
				confirmPassword: "", // check password
				accountCreated: true, // error windown validaiton
				loading: false,
				loading1: false,
				privacyDialog: false,

				// * VALIDATIONS RULES
				rules1: [],
				rules2: [],

				registerErrorMessage: null,
			};
		},

		computed: {
			// * Checks if the password matches the confirm password
			passwordConfirmationRule() {
				return () => this.password === this.confirmPassword || "Password must match";
			},
			sortPositions(){
                return this.playersPositions.sort()
            },
			sortForwardBack(){
				return this.forwardBackArray.sort()
			}
		},
		methods: {
			
			//* VALIDATING FIRST WINDOW AND MOVING TO NEXT STEP (IF VALID)
			validateDetails: async function () {
				this.rules1 = {
					nameRules: [(v) => !!v || "Full name is required"],
					emailRules: [(v) => !!v || "E-mail is required", (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
					telephoneNumberRules: [(v) => !!v || "Telephone Number is required"],
					fieldRequired: [(v) => !!v || "The field is required"],
					privacyRules: [(v) => !!v || "Please confirm"],
					requiredField: [(v) => !!v || "Field required"],
				};
				this.$nextTick(() => {
					if (this.$refs.registerUser.validate()) {
						this.step++; // * move to the next step
					} else {
						// ! did not pass validation
						this.MIX_alertBox({ color: "error", timeout: 2000, message: "Fill out all the fields correctly in order to proceed.", show: true });
					}
				});
			},
			//* VALIDATING SECOND WINDOW AND REGISTERING (IF VALID)
			validatePassword() {
				var t = this;
				this.rules2 = {
					passwordRules: [(v) => !!v || "Password is required"],
					confirmRules: [(v) => !!v || "Confirm password required"],
				};
				this.$nextTick(() => {
					if (this.$refs.userPassword.validate()) {
						t.register();
					} else {
						// ! user did not pass input validation
						t.accountCreated = false;
						t.MIX_alertBox({ color: "error", timeout: 2000, message: "Fill out all the fields correctly in order to proceed.", show: true });
					}
				});
			},
			//* REGISTER
			async register() {
				var t = this;
				const auth = getAuth();
				this.step++; // * move to the next step
				t.form.userEmail = t.form.userEmail.toLowerCase(); // set email to lowercase
				// * CREATE AUTH USER
				createUserWithEmailAndPassword(auth, t.form.userEmail, t.password)
					.then(async (user) => {
						var userId = user.user.uid;
						// * SET USER DETAILS
						t.setUserDetails(userId);
						//* CREATE USER ACCOUNT
						await t.MIX_firestore_create(t.form, "users");
						await updateProfile(auth.currentUser, {displayName: t.form.userName})
							.then(async () => {
								const user = auth.currentUser;
								// * SET AS CURRENT USER
								await t.MIX_FIREBASE_userAuth(user);
								t.accountCreated = true;
								t.MIX_alertBox({ color: "success", timeout: 2000, message: "Please verify your account on email", show: true });
								//* SEND VERIFICATION EMAIL TO USER
								sendEmailVerification(auth.currentUser).then(() => {});
							})
							.catch((error) => {
								t.MIX_alertBox({ color: "error", timeout: 2000, message: error, show: true });
							});
					})
					.then(() => {
						// * REDIRECT USER TO HOMEPAGE
						t.$router.push("/").catch((error) => {
							// console.log(error.message);
						});
					})
					.catch((err) => {
						// ! firebase registering error
						t.registerErrorMessage = err;
						t.accountCreated = false;
						t.MIX_alertBox({ color: "error", timeout: 2000, message: err, show: true });
					});
			},
			//* SETTING DETAILS TO USER
			setUserDetails(uid) {
				var t = this;
				t.form.id = uid; // * assign firebase auth user id to firebase user in collection
				t.form.createdDateTime = t.$moment().format("x");
				t.form.createdUserId = uid;
				t.form.createdUserName = t.form.userName;
				t.form.createdUserEmail = t.form.userEmail;
				t.form.modifiedDateTime = t.$moment().format("x");
				t.form.modifiedUserId = uid;
				t.form.modifiedUserName = t.form.userName;
				t.form.modifiedUserEmail = t.form.userEmail;
			},
			//* SEND VERIFICATION EMAIL
			sendVerificationEmail(currUser) {
				var t = this;
				var userAuth = currUser;
				userAuth
					.sendEmailVerification()
					.then(function () {
						t.MIX_alertBox({ color: "warning", timeout: 2000, message: "An account verification email has been sent to you.", show: true });
					})
					.catch(function (error) {
						// ! verification email error
						// console.log("err: " + error);
						t.MIX_alertBox({ color: "error", timeout: 2000, message: error, show: true });
					});
			},
		},
	};
</script>
