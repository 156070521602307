import Vue from "vue";
import App from "@/App.vue";
import router from "@/router.js";
import vuetify from "@/vuetify.js";
import store from "@/store.js";
import axios from "axios";
import moment from "moment";
import "animate.css";

import VueMeta from "vue-meta";

Vue.use(VueMeta);

import config from "@/config.js";
Vue.prototype.$config = config;

// * GLOBAL MIXIN
import mixin from "./mixin.js";
Vue.use(mixin);

// * GLOBAL COMPONENTS * // * GLOBAL COMPONENTS * // * GLOBAL COMPONENTS * // * GLOBAL COMPONENTS * //

// App Text
import appText from '@/components/base/app_text.vue';
Vue.component('app-text', appText)
import appBtn from '@/components/commonComponents/appBtn.vue';
Vue.component('appBtn', appBtn)
import appIcon from '@/components/commonComponents/appIcon.vue';
Vue.component('appIcon', appIcon)
// Page Loader
import pageLoader from '@/components/commonComponents/pageLoading.vue';
Vue.component('pageLoader', pageLoader);
// Page Title
import pageTitle from '@/components/commonComponents/pageTitle';
Vue.component('pageTitle', pageTitle)
// App Input
import appInput from '@/components/commonComponents/appInput';
Vue.component('appInput', appInput)
// Edit Icon
import editIcon from '@/components/commonComponents/editIcon.vue';
Vue.component('editIcon', editIcon)
import closeIcon from '@/components/commonComponents/closeIcon.vue';
Vue.component('closeIcon', closeIcon)
// Delete Icon Dialog
import deleteIconDialog from '@/components/commonComponents/deleteIconDialog.vue';
Vue.component('deleteIconDialog', deleteIconDialog);
// App Buttons
import appbutton from '@/components/base/app_button.vue';
Vue.component('appbutton', appbutton)

//* FIREBASE INTEGRATION
const firebase = require("@/firebase/firebase-config.js"); // Import Firebase Config for Firebase Object Access
Vue.prototype.$firebase = firebase; // Prototype Firebase for use throughout Project $firebase
import firebase_auth_mixin from "@/firebase/firebase_auth_mixin.js"; //
Vue.use(firebase_auth_mixin);
import firebase_firestore_mixin from "@/firebase/firebase_firestore_mixin.js"; //
Vue.use(firebase_firestore_mixin);
// Load User Auth into Vuex
import { doc, getDoc } from "firebase/firestore";

firebase.auth.onAuthStateChanged(async (user) => {
	// console.log('main.js - onAuthStateChanged ')
	if (user) {
		store.dispatch("firebase_auth_store/ACT_FIREBASE_userAuth", user);
		const docRef = doc(firebase.db, "users", user.uid);
		const docSnap = await getDoc(docRef);
		//console.log('docSnap = ' + JSON.stringify(docSnap.data(), null, 2));
		if (docSnap.exists()) {
			//console.log('After Refresh this is the user id = ' + user.uid + ' status ' + docSnap.data().status);
			store.dispatch("firebase_auth_store/ACT_FIREBASE_userStatus", docSnap.status);
		}
	}
});

//* BASE COMPONENTS
// alertBox Component - Requires alertBox entry in store.js and mixin.js
import alertBox from "@/components/base/alertBox.vue";
Vue.component("alertbox", alertBox);
// appUpdate Component - Requires appUpdate entry in store.js and mixin.js
import appUpdate from "@/components/base/appUpdate.vue";
Vue.component("appupdate", appUpdate);

// GLOBAL PROTOTYPES
Vue.prototype.$axios = axios;
Vue.prototype.$moment = moment;

// * LODASH INTEGRATION
import lodash from 'lodash-es';
Vue.prototype.$_ = lodash;

// * Sort By
import { sortBy } from 'lodash-es';
Vue.prototype.$sortBy = sortBy;

// * PHOTOUPLOAD COMPONENTS
import photoUpload from "@/components/photoUpload/photoUpload_button_component.vue";
Vue.component('photoupload', photoUpload)
// * PHOTOUPLOAD MIXINS
import photoUpload_mixin from '@/components/photoUpload/photoUpload_mixin.js';
Vue.use(photoUpload_mixin);


// * Apex Charts
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)


// CSS Import
import "@/assets/css/styles.min.css";

import './registerServiceWorker'
import sw_mixin from '@/sw_mixin.js'; // Import Service Work Mixing for Global SW Functions
Vue.use(sw_mixin); // Use Service Worker Mixing within Vie

Vue.config.productionTip = false;

new Vue({
	router,
	vuetify,
	store,
	render: (h) => h(App),
}).$mount("#app");
