<template>
	<v-app>
		<!-- GLOBAL COMPONENTS -->
		<alertbox />

 		  <v-banner v-if="updateExists" height="72px" app sticky class="secondary noprint"
                  style="width: 100% !important; position: fixed !important; z-index: 1000 !important;">
            <v-row no-gutters class="d-flex justify-center align-center pa-3">

                <!--Label-->
                <app-text category="text-default" class="black--text">App Update Available!</app-text>

                <!--Update button-->
                <v-btn @click="refreshApp"
                       class="animate__animated animate__pulse primary animate__infinite ml-4"
                       dark
                       small
                       :height="buttonSizeDefault">
                    <app-text category="text-default">Update Now</app-text>
                </v-btn>

            </v-row>
        </v-banner>

		<!-- APPLICATION TOP APP BAR -->
		<v-app-bar app color="primary" dark clipped-left elevation="3" v-if="!$route.meta.hideNavbar">
			<v-icon @click="MIX_go('/')" class="" color="white">icons8-home-page</v-icon>
			<v-spacer></v-spacer>
			<v-img src="@/assets/logo.png" contain width="50" height="45"></v-img>
			<v-spacer></v-spacer>
			<v-menu style="z-index: 1001 !important" offset-y origin="top right" min-width="160px"  left transition="scale-transition">
				<template v-slot:activator="{ on, attrs }">
					<v-icon class="" v-bind="attrs" v-on="on" color="white">icons8-menu-rounded</v-icon>
				</template>
				<v-list>
					<v-list-item to="/userProfile">
						<app-icon icon="users" color="primary"  size="24"></app-icon>
						<app-text class="text-default ml-2">Profile</app-text>					
					</v-list-item>
					<v-list-item @click="MIX_FIREBASE_logout">
						<app-icon icon="logout"  color="primary" size="24"></app-icon>
						<app-text class="text-default ml-2">Log Out</app-text>
					</v-list-item>
					<!--App version-->
                    <v-list-item>
                        <!-- <v-list-item-title class="grey--text ">App Version {{ LATEST_VERSION }} </v-list-item-title> -->
           				<app-text class="text-default ml-2 grey--text">App Version {{ LATEST_VERSION }} </app-text>         
					</v-list-item>
				</v-list>
			</v-menu>
		</v-app-bar>
		<!-- MOBILE NAVIGATION -->
		<mobile-nav v-if="GET_FIREBASE_userAuth && $vuetify.breakpoint.xsOnly && !$route.meta.hideNavbar" class="secondary"/>
		<!-- DESKTOP / TABLET NAVIGATION -->
		<v-navigation-drawer app class="secondary" clipped expand-on-hover floating style="" :mini-variant.sync="mini" mobile-breakpoint="601"
			v-if="GET_FIREBASE_userAuth && !$route.path.includes('/holding')">
			<side-nav />
		</v-navigation-drawer>
		<v-main class="appBackground">
			<v-container fluid fill-height class="d-flex align-start pa-0">
				<transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in">
					<router-view />
				</transition>
			</v-container>
		</v-main>
		<!-- <v-main class="appBackground">
			<v-container fluid class="d-flex flex-column pa-0" style="height:100vh; display:grid; grid-template-rows:1fr auto;" >
				<transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in">
					<router-view />
				</transition>
				<v-footer class="d-flex justify-center appBackground darken-1 pa-4">Copyright Vindico 2022 &#169;</v-footer>
			</v-container>


		</v-main> -->
		<!-- <app-update :show="GET_appUpdate.show"/> -->
		<v-bottom-navigation class="grey lighten-4  d-flex align-center"><div class="caption font-weight-bold grey--text">Copyright Vindico 2022 &#169;</div></v-bottom-navigation>
	</v-app>
</template>

<script>
	import { mapActions, mapGetters } from "vuex";
	import sideNavigation from "@/components/navigation/sideNavigation.vue";
	import mobileNav from "@/components/navigation/mobileNav.vue"
	import {version as appVersionNumber} from '../package.json'
	import AppUpdate from './components/base/appUpdate.vue';

	export default {
		name: "App",
		components: {
			"side-nav": sideNavigation,
			"mobileNav": mobileNav,
AppUpdate,
		},
		metaInfo() {
			return {
				title: this.$config.organisation + " - " + this.$config.appname,
			};
		},
		data: () => ({
            LATEST_VERSION: appVersionNumber,
			mini: true,
		}),
		computed: {
			...mapGetters({
				GET_dense: "GET_dense",
				GET_appUpdate: "GET_appUpdate",
				GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
				GET_FIREBASE_userStatus: "firebase_auth_store/GET_FIREBASE_userStatus"
			}),
		},
		methods: {
			...mapActions({
				ACT_taskBar: "ACT_taskBar",
				ACT_dense: "ACT_dense",
			}),
		},
		async created() {
			var dense = localStorage.getItem("dense");
			if (dense === "true") {
				this.ACT_dense(true);
			} else {
				this.ACT_dense(false);
			}
		},
	};
</script>

<style>

	#app{
		color: #444444;
	}

	.v-banner__wrapper {
		padding: 0px !important;
	}
</style>
