<template>
	<div class="pa-4" style="width: 100%">

		<!--Page Tittle-->
		<v-card class="pa-5 mt-1 d-flex align-center" elevation="0" style="width: 100%">
			<div class="">
				<span class="text-h5 grey--text">Hello</span> <span class="text-h5 primary--text font-weight-bold" v-if="GET_FIREBASE_userAuth">{{userDataResult.userName}},</span>
				<div class="mt-4 text--secondary text-h8 font-weight-normal">
					Welcome to <span class="font-weight-bold">{{ $config.organisation }}</span> Development Passport, your pathway to the future.
				</div>
			</div>
		</v-card>

		<!--Number of sessions-->
		<v-card v-if="['Player-User'].includes(userDataResult.userLevel)" class="d-flex align-center mt-4 pa-4 " flat style="width: 100%" to="/sessions">
			<v-icon  class="mr-4" size="48">icons8-stopwatch-2</v-icon>
			<app-text category="text-large-bold" class="primary--text mr-2">{{numberOfSessions()}}</app-text>
			<app-text category="text-small" class="primary--text mr-2">sessions</app-text>
			<app-text category="text-large-bold" class="primary--text mr-2">{{accumulativeTimeCal()}} </app-text>
			<app-text category="text-small" class="primary--text mr-2">hrs</app-text>			
		</v-card>

		<!--Photo Upload-->
		<photoupload  v-if="GET_showUploadPicture" class="mr-0" :chooseLayout="false" allowedtypes="image/*" :docLink="{collection: 'users', documentId: GET_FIREBASE_userAuth.uid}" folder="users-profile-pictures"/> 		
		
		<!--IDP-->
		<v-card v-if="['Player-User'].includes(userDataResult.userLevel)" class="py-5 pl-5 mt-3 d-flex" elevation="0" style="width: 100% !important" @click="MIX_go('/idp')">
			<v-icon x-large >icons8-grid-2</v-icon>
			<div class="ml-5"><span class="primary-text text-h7 font-weight-bold">IDP</span><br /><span class="text--secondary">Create and view IDPs</span></div>
		</v-card>
	
		<!--Sessions-->
		<v-card v-if="['Player-User'].includes(userDataResult.userLevel)" class="pa-4 mt-4 d-flex" elevation="0" style="width: 100%" to="/sessions">
			<v-icon x-large>icons8-stopwatch-2</v-icon>
			<div class="ml-5"><span class="primary-text text-h7 font-weight-bold">Sessions</span><br /><span class="text--secondary">Log and view Sessions</span></div>
		</v-card>

		<!--Matches-->
		<v-card v-if="['Player-User'].includes(userDataResult.userLevel)" class="pa-4 mt-4 d-flex" elevation="0" style="width: 100%" @click="MIX_go('matches')">
			<v-icon x-large>icons8-rugby-3</v-icon>
			<div class="ml-5"><span class="primary-text text-h7 font-weight-bold">Matches</span><br /><span class="text--secondary">Log and view Matches</span></div>
		</v-card>
		
		<!--Awaiting Approval/Users-->
		<v-card v-if="['Staff-Admin'].includes(userDataResult.userLevel)" class="pa-4 mt-4 d-flex" elevation="0" style="width: 100%" @click="MIX_go('user-directory')">
			<v-icon x-large >icons8-user-2</v-icon>
			<div class="ml-4"><span class="primary-text text-h7 font-weight-bold">Users Awaiting Approval</span><br /><span class="text--secondary">You have {{numberOfUsers}} awaiting approval</span></div>
		</v-card>
		
	</div>
</template>

<script>
	import { mapActions, mapGetters } from "vuex";

	export default {
		name: "Home",
		
		data:() => ({
			sessionsArray: [],
			userDataResult:{},
			showPicture: true,
			// showIDP: true,
			numberOfUsers:"",
		}),
		computed: {
			...mapGetters({
				GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
				GET_showUploadPicture:"GET_showUploadPicture"
			}),
		},
		methods: {
			...mapActions({
				ACT_showUploadPicture: "ACT_showUploadPicture",
			}),
			
			checkUserProfile(){
				if(this.userDataResult !={}){
					if(this.userDataResult.userProfile === ""){
						this.ACT_showUploadPicture(true);
					}else{
						this.ACT_showUploadPicture(false);
					}
				}
			},
			accumulativeTimeCal(){
				let sum =0;
				if( this.sessionsArray.length !=0){
					for (var i=0; i< this.sessionsArray.length; i++){
						sum = sum + this.sessionsArray[i].sessionDuration;
					}
				}
				return parseFloat(sum /60).toFixed(2);
			},
			numberOfSessions(){
				if( this.sessionsArray.length !=0){
					return this.sessionsArray.length
				}else{
					return 0
				}
			},
		},

		watch: {
			showPicture: {
				handler() {
					if(this.GET_showUploadPicture === true) {
						this.showPicture = true
					} else {
						this.showPicture = false
					}
				}, immediate: true
			}
		},
		async mounted() {
		},
		async created(){
			this.sessionsArray = await this.MIX_firestore_readManyWhereCondition("sessions", "userId", "==", this.GET_FIREBASE_userAuth.uid);
			
			let userDataResult1 = await this.MIX_firestore_read(this.GET_FIREBASE_userAuth.uid, 'users');
			this.userDataResult = userDataResult1.data;
			this.checkUserProfile();
			let numberOfUsers1 = await this.MIX_firestore_readManyWhereCondition("users", "userStatus", "==", "Pending");
			this.numberOfUsers = numberOfUsers1.length;
		}
	};
</script>
