// import {mapGetters, mapActions} from 'vuex';
import moment from 'moment';
import { mapActions } from "vuex";
import imageCompression from 'browser-image-compression';


const mixin = {
	data: () => ({
		buttonSizeDefault: 48,
		accessAndAbilitiesData:[
			'Fire Marshall',
			'First Aider',
			'Coach',
			'Medic'
		],
		forwardBackArray: ['Forward', 'Back', 'N/A'],

		userStatusOptionsData: [
			'Pending',
			'Approved',
			'Suspended',
			'Rejected',
			'Archived',
		],
		userLevelsOptionsData: [
			'Coach-User', 'Player-User', 'Staff-Admin'
		],
		playersPositions:[
			'Prop', 'Hooker', 'Second Row', 'Back Row', 
                            'Open Side Flanker', 'Blindside Flanker', 'Number 8',
                            'Scrum Half', 'Outside Half', 'Center', 'Wing', 'Fullback',
		]


	}),

	methods: {
		...mapActions({
			ACT_alertBox: "ACT_alertBox",
			ACT_panelLayout:'ACT_panelLayout',

		}),
		MIX_sortPositions() {
			const t = this
			return t.playersPositions.sort()
		},
		MIX_breakpoint() {
			return (this.$vuetify.breakpoint.name);
		},
		// * VIEW RELATED MIXINS
		MIX_closeView() {
			this.MIX_puDialog(false, '', '', '');
			this.MIX_fsDialog(false, ''); // Close Full Screen Dialog
			// this.ACT_panelLayout({ leftPanel: 12, rightPanel: 0, show: false, dynamicComponent: '' });
		},
		MIX_itemsPerPage: function (breakpoint) {
			var result = 5;
			switch (breakpoint) {
				case 'xs':
					result = 1;
					break;
				case 'sm':
					result = 5;
					break;
				case 'md':
					result = 10;
					break;
				case 'lg':
					result = 10;
					break;
				case 'xl':
					result = 10;
					break;
				default:
					result = 5;
					break;
			}
			return result;
		},
		// * PANEL LAYOUT
		MIX_setPanelLayout(leftPanel, rightPanel, show, title, dynamicComponent) {
			this.ACT_panelLayout({
				leftPanel: leftPanel,
				rightPanel: rightPanel,
				show: show,
				title: title,
				dynamicComponent: dynamicComponent
			});
		},
		// * CHANGE ROUTE FUNCTION
		MIX_go(path) {
			this.$router.push(path).catch((err) => {
				console.log("Route error: " + err);
			});
		},
		// * FORMAT DATE TIME
		MIX_formatDateTime: function (date, formatIn, formatOut) {
			if ((date !== '') && (date !== null) && (date !== undefined)) {
				return moment(date, formatIn).format(formatOut);
			} else {
				return ''
			}
		},
		MIX_fromNow: function(date, formatIn, ago) {
            return moment(date, formatIn).fromNow(ago);
        },
		MIX_alertBox: function(payload) { this.ACT_alertBox(payload) }, // alertBox Mix
		MIX_updateApp: function() { alert('Update App') },

		// * Compress and return image for uploading
		async MIX_compressImage(image) {
			const options = {
			  maxSizeMB: 1,
			  maxWidthOrHeight: 400,
			  useWebWorker: true,
			}
			try {
			  return await imageCompression(image, options)
			} catch (error) {
			  console.log('compression error: ', error)
			  return image
			}

		},
		MIX_exportDocuments(headers, fileTitle, formatExport) {
			const items = formatExport

			if (headers && items[0] !== headers) {
				items.unshift(headers)
			}

			const jsonObject = JSON.stringify(items)

			// convert object to csv
			const array = typeof jsonObject != "object" ? JSON.parse(jsonObject) : jsonObject

			let str = ""

			for (let i = 0; i < array.length; i++) {
				let line = ""

				for (let index in array[i]) {
					if (line !== "") line += ","
					line += array[i][index];
				}

				str += line + "\r\n"
			}

			const csv = str
			const exportedFilename = fileTitle + ".csv" || "export.csv"
			const blob = new Blob([csv], {type: "text/csv;charset=utf-8;"})

			if (navigator.msSaveBlob) {
				navigator.msSaveBlob(blob, exportedFilename);
			} else {
				const link = document.createElement("a")

				if (link.download !== undefined) {
					const url = URL.createObjectURL(blob)
					link.setAttribute("href", url)
					link.setAttribute("download", exportedFilename)
					link.style.visibility = "hidden"
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				}
			}
		},
	}
}

export default {
	install(Vue) {
		Vue.mixin(mixin)
	}
}
