<template>
	<v-bottom-sheet v-model="show" persistent  app sticky class="" style="width: 100% !important; position: fixed !important; z-index: 1000 !important">
		<v-sheet height="100px" no-gutters class="red d-flex justify-center align-center pa-1">
			<v-spacer />
			<div>App Update Available!</div>
			
			<!--Update button-->
			<div><v-btn @click="MIX_updateApp" class="primary ml-4 white--text animate__animated animate__pulse primary animate__infinite" dark large>Update Now</v-btn></div>
			<v-spacer />
			<div class="mr-5"> 
				<v-icon @click="show = false" >mdi-close</v-icon>
			</div>
		</v-sheet>
	</v-bottom-sheet>
</template>

<script>
	export default {
		name: "appUpdate",
		props: {
			show: {
				type: Boolean,
				default: false
			}	
		},
	};
</script>
