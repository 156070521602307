<template>
  <div class="clearfix">

      <div class="chat-history" id="scroll-to-bottom">

        <div v-for="(msg, i) in chat" :key="i" class="d-flex" :class="{ ' flex-row-reverse': msg.user === currUserData }">

          <v-card class="my-4 mt-4 pa-4" style="border-radius: 10px; max-width:75%" elevation="0" :class="msg.user === currUserData ? 'primary white--text': 'grey lighten-2  dark--text'">
            
            <!--Sender Details-->
            <v-row :class="{ 'd-flex flex-row-reverse':  msg.user === currUserData  }">
              <v-icon size="18" 
              :class="msg.user === currUserData  ? 'secondary--text': 'primary--text'"
              >{{checkMessenger(msg.user)}}</v-icon>
              <span class="ml-4 mr-4 caption ">{{ dayTimeMinFormat(msg.created_at) }} | {{ msg.user }}</span>
            </v-row>

            <!--Content-->
            <v-row>
              <span class="mt-4 ml-4 text-left"> {{ msg.content }}</span>
            </v-row>
          </v-card>
        </div>
      </div>
      <!-- end of chat-->

      <!--Send Button Message-->
      <div class="messageButton">
        <v-divider class="primary"/>
        <v-textarea
          class="mt-4 rounded-lg"
          background-color="white"
          required
          v-model="messageForm.content"
          :error="errors.message"
          :error-messages="errors.messageErrorMessage"
          outlined
          label="What do you want to say?"
          rows="1"
          hide-details="auto"
          append-outer-icon="icons8-send"
          @click:append-outer="sendComment()"/>
      </div>
      <!-- end button-message -->

  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    name: "Chat",

    props: {
      chat: Array,
      sessionOrMatch: Boolean,
      id: String,
      creator: String,
    },
    data() {
      return {

        currUserData: "",
        isPageLoading: true,
        messageArray:[],

        messageForm: {
          content: "",
          user: "",
          created_at: "",
        },

        errors: {
          message:false,
          messageErrorMessage:"",
        }
      };
    },
      
    computed: {
      ...mapGetters({
        GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
      }),
    },

    methods: {
      /**
       * This method validated the messages before send it
       * Check if the message is empty 
       */
      validateMessage(){
        this.errors.message = false;
        this.errors.messageErrorMessage="";
        // Date
        if (!this.messageForm.content.trim() ) {
          this.errors.message = true
          this.errors.messageErrorMessage = 'No message has been entered'
        }
        // Check if there any errors left
        if (!Object.values(this.errors).includes(true)) {
          return true;
        }
      },

      /**
       * Checks who is the sender of the message and returns the corresponding icon
       * @param {*} messenger The name of the sender
       * @return Returns the icons that match the sender
       */
      checkMessenger(messenger) {
        if (messenger === this.creator) {
          return "icons8-user-2";
        } else {
          return "icons8-whistle-3";
        }
      },

      /**
       * Format time
       * @param {*} time when the message was sended/created
       * @return The new formated time
       */
      dayTimeMinFormat(date) {
        return this.$moment(date, "X").format("DD MMM YY | HH:mm");
      },

      /**
       * Send the message to the chat, diplay it, and save it to the db
       */
      async sendComment() {
        if(this.validateMessage()){
          this.messageForm.user = this.currUserData;
            this.messageForm.created_at = this.$moment().format("X");
            this.chat.push(this.messageForm);
            this.messageArray.push((this.messageForm))

            //If it is a sesion
            if (this.sessionOrMatch) {
              await this.MIX_firestore_update(this.id, "sessions","sessionComments",this.chat);
            } else { // if it is a match review comment
              await this.MIX_firestore_update(this.id,"matchReviews","matchComments", this.chat);
            }
            this.messageForm = {};
            this.messageForm.content="";
            this.scrollToTheBottom();
        }
      },
      
      /**
       * Ensures that the chat component always scrooll to the last message
       */
      scrollToTheBottom() {
        let chatHisroty = document.getElementById("scroll-to-bottom");
        // scroll_to_bottom.scrollTop = scroll_to_bottom.scrollHeight;
        chatHisroty.scrollTo({
          left: 0,
          top: document.body.scrollHeight *5,
          behavior: "smooth",
        });
      },

      /**
       * Reading User Data
       */
      async readUserData() {
        let userDataResult = await this.MIX_firestore_read(this.GET_FIREBASE_userAuth.uid, "users");
        this.currUserData = userDataResult.data.userName;
        this.scrollToTheBottom();
      },
    },


    created() {
      this.readUserData();
    },
  };
</script>

<style >
  .chat-history {
    padding: 16px;
    overflow-y: scroll;
    height:576px;
  }

  @media screen and (min-height: 1000px){
    .chat-history{
      height: 720px;
    }
  }

  @media screen and (min-height: 1100px){
    .chat-history{
      height: 770px;
    }
  }

  @media screen and (min-height: 1200px){
    .chat-history{
      height: 840px;
    }
  }
  
  .messageButton {
    padding: 16px;
  }
</style>
