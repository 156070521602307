<template>
    <v-row no-gutters>

        <!--Page Loader-->
        <pageLoader :is-page-loading="isPageLoading"/>

        <!--Page Content-->
        <v-row v-if="!computedIsPageLoading" no-gutters>

            <!--Left--Panel-->
            <v-col v-if="computedSplitScreenColumns.left" :cols="computedSplitScreenColumns.left" class="">

                <!--Page Titile-->
                <div class="mt-4 mx-4" >

                    <div class="d-flex justify-space-between align-start">
                        <div>
                            <page-title icon="icons8-team" pageTitle="Squad" divider description="View player's Sessions and Matches, and provide feedback."/>
                        </div>
                        <!-- <v-spacer></v-spacer> -->
                        <div v-if="showPlayersIDP && $vuetify.breakpoint.width >=700" class="d-flex align-center">
                            <div class="text-right mr-2" >
                                <app-text category="text-default-bold" class="mb-2">{{ selectedPlayer.userName }}</app-text>
                                <app-text category="text-small" class="grey--text">{{ selectedPlayer.userPlayerPosition }}</app-text>
                            </div>
                            <v-img v-if="selectedPlayer.userProfile!=''" :src="selectedPlayer.userProfile" class="rounded-lg" cover max-height="80" max-width="80" style="aspect-ratio:1/1"/>
                            <v-icon v-else class="icons8-user-2" color="primary" size="80"/>
                        </div>
                    </div>
                </div>


                <!--Tabs Squads-->
                <v-tabs v-model="tabs" dark background-color="primary" class=""  @change="isRightPanelOpen = false; filterPlayerSesssion=''" show-arrows centered grow>
                    <v-tabs-slider color="secondary"/>
                    <v-tab v-if="!showPlayersIDP" href="#players">
                        <app-text category="text-small">ALL PLAYERS</app-text>
                    </v-tab>
                    <v-tab v-else href="#idp">
                        <app-text category="text-small">IDP</app-text>
                    </v-tab>
                    <v-tab href="#sessions">
                        <app-text v-if="!showPlayersIDP" category="text-small">ALL SESSIONS</app-text>
                        <app-text v-else category="text-small">SESSIONS</app-text>
                    </v-tab>
                        <v-tab href="#matches">
                        <app-text v-if="!showPlayersIDP" category="text-small">ALL MATCHES</app-text>
                        <app-text v-else category="text-small">MATCHES</app-text>
                    </v-tab>
                </v-tabs>

                <!--Tab Squad Content-->
                <v-tabs-items v-if="!showPlayersIDP" class="appBackground" v-model="tabs" >
                    <!--Overview Tab Content-->
                    <v-tab-item class="" value="players">
                        <squad-players :usersData="computedUsersData" @selectedPlayer="updateSelectedPlayer"/>
                    </v-tab-item>
                    <v-tab-item  class="" value="sessions">
                        <squad-sessions @rightPanel="updateRightPanel" @selectedSession="updateSelectedOptions" />
                    </v-tab-item>
                        <v-tab-item  class="" value="matches">
                            <squad-matches @rightPanel="updateRightPanel" @selectedSession="updateSelectedOptions"/>
                        </v-tab-item>
                </v-tabs-items>


                <!-- Coach Contant-->
                <v-tabs-items class="appBackground"  v-else v-model="tabs">
                    <!--Overview Tab Content-->
                    <v-tab-item  value="idp">
                        <player-IDP :usersData="selectedPlayer" :skills="skills" @filterSessions="updateFilterSessions" @updateShowPlayersFlag = updateShowPlayersFlag></player-IDP>
                    </v-tab-item>
                    <v-tab-item   value="sessions">
                        <player-sessions :usersData="selectedPlayer" :filter="filterPlayerSesssion" @rightPanel="updateRightPanel" @selectedSession="updateSelectedOptions" @updateShowPlayersFlag = updateShowPlayersFlag> </player-sessions>
                    </v-tab-item>
                    <v-tab-item   value="matches">
                        <player-matches  :usersData="selectedPlayer" @rightPanel="updateRightPanel" @selectedSession="updateSelectedOptions" @updateShowPlayersFlag = updateShowPlayersFlag></player-matches>
                    </v-tab-item>
                </v-tabs-items>

            </v-col>
            <!--Right Panel-->
            <v-col v-if="isRightPanelOpen" :class="computedSplitScreenColumns.left && 'borderLeft'" :cols="computedSplitScreenColumns.right" class="">
                <!--Action Bar-->
                <div class="d-flex align-center justify-space-between appbackground pa-4  ">
                    <div class="d-flex align-center">
                        <v-img v-if="selectedUser.userProfile!=''" :src="selectedUser.userProfile" class="rounded-lg" cover max-height="80" max-width="80" style="aspect-ratio:1/1"/>
                        <v-icon v-else class="icons8-user-2" color="primary" size="80"/>
                        <div class="d-flex flex-column ml-4">
                            <app-text category="text-default-bold" class="mb-2">{{ selectedUser.userName }}</app-text>
                            <app-text category="text-small" class="grey--text">{{ selectedUser.userPlayerPosition }}</app-text>
                        </div>
                    </div>
                    <v-spacer/>
                    <div>
                        <!--Close Icon-->
                        <close-icon @click.native="emittedCloseRightPanel"/>
                    </div>
                </div>

                <!--Tabs Right Panel (Overview)-->
                <v-tabs class=" " v-model="tabsRightPanel" dark background-color="primary" show-arrows>
                    <v-tabs-slider color="secondary"/>
                    <v-tab v-if="tabs==='sessions'" href="#sessionForm">
                        <app-text category="text-small">Session</app-text>
                    </v-tab>
                    <v-tab v-if="tabs==='matches'" href="#matchesForm">
                        <app-text category="text-small">Match</app-text>
                    </v-tab>
                    <v-tab  href="#feedback">
                        <app-text category="text-small">Comments</app-text>
                    </v-tab>
                </v-tabs>

                <!--Tab Content Right Panel-->
                <v-tabs-items v-if="tabs==='sessions'" class="appBackground" v-model="tabsRightPanel">
                    <!--Overview Tab Content Right Panel-->
                    <v-tab-item value="sessionForm">
                        <session-form :doesFormStartReadOnly="isFormReadOnly" :formData="selectedItem" v-on:emitCloseRightPanel="emittedCloseRightPanel"/>
                    </v-tab-item>
                    <v-tab-item  value="feedback">
                        <chat :chat="selectedItem.sessionComments" :id="selectedItem.id" :sessionOrMatch="true" :creator="selectedItem.userName"/>
                    </v-tab-item>
                </v-tabs-items>

                <v-tabs-items v-if="tabs==='matches'" class="appBackground" v-model="tabsRightPanel">
                 <v-tab-item  value="matchesForm">
                        <match-form :doesFormStartReadOnly="isFormReadOnly" :formData="selectedItem" v-on:emitCloseRightPanel="emittedCloseRightPanel"/>
                    </v-tab-item>
                    <v-tab-item  value="feedback">
                        <chat :chat="selectedItem.matchComments" :id="selectedItem.id" :sessionOrMatch="false" :creator="selectedItem.userName"/>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-row>
</template>

<script>
    import {mapGetters} from "vuex";
    import SquadSessions from '@/views/squad/squadSessions/squadSessions.vue';
    import SquadPlayers from '@/views/squad/squadPlayers/squadPlayers.vue';
    import PageTitle from '../../components/commonComponents/pageTitle.vue';
    import SquadMatches from './squadMatches/squadMatches.vue';
    import SessionForm from '@/views/sessions/sessionForm/sessionForm.vue';
    import MatchForm from '@/views/matches/matchesForm/matchForm.vue';
    import Chat from '../chat/chat.vue';
    import PlayerIDP from '../player/playerIDP/playerIDP.vue';
    import PlayerSessions from '../player/playerSessions/playerSessions.vue';
    import PlayerMatches from '../player/playerMatches/playerMatches.vue';

    export default {

        name: "squad",

        components: {  SquadPlayers, SquadSessions, PageTitle, SquadMatches , SessionForm, MatchForm,  Chat, PlayerIDP, PlayerSessions, PlayerMatches},

        data: () => ({
            isFormReadOnly: true,
            filterTeam:"",
            selectedItem:"",
            isRightPanelOpen:false,
            showPlayersIDP:false,
            filterPlayerSesssion:"",
            tabs: '',
            tabsRightPanel: '',
            selectedUser:'',
            selectedPlayer:'',
            // Collection Data
            skillsArray:[],
            usersCollectionData: [],
            isusersCollectionDataLoaded: false,
            isPageLoading: true,
        }),
        computed: {
            ...mapGetters({
                GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
            }),
            /**
             * Computed Split Screen Columns
             *
             * Calculate the width of the left snd right panels, based on the currently selected functionality.
             * The left panel has the table, the right panel has the forms.
             *
             * @returns {{left: number, right: number}}
             */
            computedSplitScreenColumns() {
                const t = this
                let left = 12
                let right = 0
                if (t.$vuetify.breakpoint.width >= 1200) {
                    if (t.isRightPanelOpen ) {
                        left = 6;
                        right = 6;
                    }
                } else {
                    if (t.isRightPanelOpen) {
                        left = 0;
                        right = 12;
                    }
                }
                return {left, right}
            },
            /**
             * Computed Is Page Loading
             *
             * Return a boolean for the page loading spinner to denote if all data has been loaded.
             *
             * @returns {boolean} if data has been loaded or not
             */
            computedIsPageLoading() {
                const t = this
                t.isPageLoading = !(
                    t.isusersCollectionDataLoaded
                )
                return t.isPageLoading
            },
            /**
             * Computed The Players Data according to the filder
             * Return a sorted array of JSON objects.
             * @returns {Array} Array of processed JSON data
             */
            computedUsersData() {
                const t = this
                let tableData = t.usersCollectionData
                // Sort alphabetically
                tableData = tableData.sort((a, b) => {
                    return a.createdDateTime > b.createdDateTime ? 1 : -1
                })
                return tableData
            },
        },
        methods: {
            async updateShowPlayersFlag(){
                this.isRightPanelOpen= false;
                this.showPlayersIDP = false;
                this.tabs='players'
                this.filterPlayerSesssion ="";
                await this.getUsersCollectionData()
            },
            updateFilterSessions(filterSkill){
                this.filterPlayerSesssion = filterSkill
                this.tabs='sessions';
            },
            /**
             * Gets the emited user from the child
             */
            updateSelectedPlayer(player){
                this.selectedPlayer = player;
                this.showPlayersIDP = true;
            },
            /**
             * Gets the emited selected session or match from the child
             */
            updateSelectedOptions(sessionOrMatch) {
                this.selectedItem = sessionOrMatch;
                this.readSelectedUserData(this.selectedItem);
            },
            /**
             * activates the right panel
             */
            updateRightPanel(){
                this.isRightPanelOpen = true;
                if(this.tabs == "sessions"){
                    this.tabsRightPanel = 'sessionForm'
                }else{
                    this.tabsRightPanel = 'matchForm'
                }
            },
            /**
             * Get Collection Data
             *
             * Fetch the data from the collection and assign them to the class state.
             *
             * @returns {Promise<void>}
             */
            async getUsersCollectionData() {
                const t = this
                let collection = await this.MIX_firestore_readManyWhereCondition('users', 'userLevel', "==", 'Player-User')
                t.usersCollectionData = collection;
                t.isusersCollectionDataLoaded = true
            },
            /**
             * Emitted Close Right Panel
             *
             * Take the emitted message and call to close the right panel.
             *
             * @returns {Promise<void>}
             */
            async emittedCloseRightPanel() {
                this.closeRightPanel()
            },
            /**
             * Close Right Panel
             *
             * When closing the form, reset it to ensure it opens as new.
             */
            closeRightPanel() {
                this.isFormReadOnly = true
                this.tabsRightPanel = 'sessionForm'
                this.isRightPanelOpen = false
            },

            /**
             * Read the User data of the user that has created the session
             */
            async readSelectedUserData(item){
                let userDataResult = await this.MIX_firestore_read( item.userId, 'users');
                this.selectedUser = userDataResult.data;
            },
             /**
             * Read the skills
             */
            async readSkills (){
                let skillsArray = await this.MIX_firestore_allData('skills')
                this.skills = skillsArray;
            }
        },
        async mounted() {
            await this.getUsersCollectionData()
        },
        async created(){
            await this.getUsersCollectionData();
            await this.readSkills();
        }
    }
</script>

<style scoped>
    .borderLeft {
        border-left: 4px solid #999999;
        min-height: calc(100vh - 128px);
    }
</style>
