const firebase_auth_store = {
    namespaced: true, //to be case sensitive
    // * STATE
    state: {
        userAuth: null,
		userStatus: null,
    },
    // * MUTATIONS
    mutations:{
        MUT_FIREBASE_userAuth (state, payload) { state.userAuth = payload },
        MUT_FIREBASE_userStatus (state, payload) { state.userStatus = payload },
    },
    // * GETTERS
    getters:{
        GET_FIREBASE_userAuth (state) { return state.userAuth },
        GET_FIREBASE_userStatus (state) { return state.userStatus },
    },
    // * ACTIONS
    actions: {
        ACT_FIREBASE_userAuth ( { commit }, payload) { commit('MUT_FIREBASE_userAuth', payload) },
        ACT_FIREBASE_userStatus ( { commit }, payload) { commit('MUT_FIREBASE_userStatus', payload) },
    }    
};
export default firebase_auth_store;