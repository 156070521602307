<template>
	<div>
		<span class="font-weight-bold text-h6">GET_FIREBASE_userAuth</span><br ><pre align="left body-2">{{ GET_FIREBASE_userAuth }}</pre>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	export default {
		computed: {
			...mapGetters({
				GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
			})
		}
	}
</script>
