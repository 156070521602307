<!--
APP TEXT

This is the main component for text within the app.
<app_text> can be called with any of the 'category' attributes below which will give it a predefined size.
-->
<template>
	<div class=" align-center">

		<div>
			<v-icon v-if="icon !== ''" :class="iconclass">{{ icon }}</v-icon>
		</div>

		<div :class="dynamicClass">
			<slot></slot>
		</div>

	</div>
</template>

<script>
export default {
	props: {
		category: {
			type: String,
			default: '',
			required: false
		},
		icon: {
			type: String,
			default: '',
			required: false
		},
		iconclass: {
			type: String,
			default: '',
			required: false
		}
	},
	computed: {
		dynamicClass: function () {
			let category;
			switch (this.category) {
				case 'text-small':
					category = 'text-small'
					break
				case 'text-small-bold':
					category = 'text-small-bold'
					break
				case 'text-default':
					category = 'text-default'
					break
				case 'text-default-bold':
					category = 'text-default-bold'
					break
				case 'text-medium':
					category = 'text-medium'
					break
				case 'text-medium-bold':
					category = 'text-medium-bold'
					break
				case 'text-large':
					category = 'text-large'
					break
				case 'text-large-bold':
					category = 'text-large-bold'
					break
				case 'text-xlarge':
					category = 'text-xlarge'
					break
				case 'text-xlarge-bold':
					category = 'text-xlarge-bold'
					break
				case 'text-xxlarge':
					category = 'text-xxlarge'
					break
				case 'text-xxlarge-bold':
					category = 'text-xxlarge-bold'
					break
				default:
					category = '';
					break;
			}

			return category;
		}
	}
}
</script>

<style lang="sass">
@import 'node_modules/vuetify/src/styles/styles'

.text-small
	font-size: 12px


.text-small-bold
	font-size: 12px
	font-weight: bold

.text-default
	font-size: 16px

.text-default-bold
	font-size: 16px
	font-weight: bold

.text-medium
	font-size: 20px

.text-medium-bold
	font-size: 20px
	font-weight: bold

.text-large
	font-size: 24px

.text-large-bold
	font-size: 24px
	font-weight: bold

.text-xlarge
	font-size: 32px

.text-xlarge-bold
	font-size: 32px
	font-weight: bold

.text-xxlarge
	font-size: 40px

.text-xxlarge-bold
	font-size: 40px
	font-weight: bold


.content-help
	font-size: 18px
	font-weight: thin

.tab-title
	font-size: 16px
	font-weight: bold

.tab-text
	font-size: 16px
	font-weight: normal

.popup-title
	font-size: 16px
	font-weight: normal

.popup-text
	font-size: 16px
	font-weight: normal

.content-help
	font-size: 18px
	font-weight: thin

.dialog-title
	font-size: 18px
	font-weight: thin

.page-title
	font-size: 28px
	max-width: 600px

.stats-error
	font-size: 40px
	max-width: 400px

.stats-warning
	font-size: 40px
	max-width: 400px

.stats-success
	font-size: 40px
	max-width: 400px

.stats-total
	font-size: 40px
	max-width: 400px


.content-title
	font-size: 20px
	max-width: 200px
</style>

