import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
//import modular stores
import firebase_auth_store from "@/firebase/firebase_auth_store.js";
import photoUpload_store from '@/components/photoUpload/photoUpload_store.js';

export default new Vuex.Store({
	modules: {
        firebase_auth_store: firebase_auth_store,
		photoUpload_store: photoUpload_store,
    },
	state: {
		alertBox: { color: "success", timeout: 2000, message: "Success", show: false }, // alertBox state
		appUpdate: { show: false },
		taskBar: false,
		dense: false,
		showUploadPicture: true,
	},
	getters: {
		GET_showUploadPicture (state){return state.showUploadPicture},
		GET_alertBox(state) { return state.alertBox }, // alertBox getters
		GET_appUpdate(state) { return state.appUpdate }, // appUpdate getters
		GET_taskBar(state) { return state.taskBar }, // taskBar getters
		GET_dense(state) { return state.dense },
	},
	mutations: {
		MUT_showUploadPicture(state, payload) { state.showUploadPicture =  payload }, // alertBox mutation
		MUT_alertBox(state, payload) { state.alertBox =  payload }, // alertBox mutation
		MUT_appUpdate(state, payload) { state.appUpdate =  payload }, // appUpdate mutation
		MUT_taskBar(state) { state.taskBar = !state.taskBar }, // taskBar mutations
		MUT_dense(state, payload) {
			state.dense = payload;
			localStorage.setItem('dense', payload);
		},
	},
	actions: {
		ACT_showUploadPicture({commit}, payload) { commit('MUT_showUploadPicture', payload) }, // alertBox action
		ACT_alertBox({commit}, payload) { commit('MUT_alertBox', payload) }, // alertBox action
		ACT_appUpdate({commit}, payload) { commit('MUT_appUpdate', payload) }, // appUpdate action
		ACT_dense({commit}, payload) { commit('MUT_dense', payload) },
		ACT_taskBar({commit}) { commit('MUT_taskBar') }, // taskBar action
	},
});
