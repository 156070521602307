import { mapActions } from "vuex";
// * GLOBAL MIXIN
const mixin = {
	methods: {
		...mapActions({
			ACT_FIREBASE_userAuth: "firebase_auth_store/ACT_FIREBASE_userAuth",
			ACT_FIREBASE_userStatus: "firebase_auth_store/ACT_FIREBASE_userStatus",
		}),
		MIX_FIREBASE_userAuth(user) {
			this.ACT_FIREBASE_userAuth(user);
		},
		MIX_FIREBASE_userStatus(status) {
			this.ACT_FIREBASE_userStatus(status);
		},
		MIX_FIREBASE_logout() {
			let t = this;
			t.$firebase.auth.signOut().then(() => {
				localStorage.removeItem('userData');
				t.$router.push("/login").catch((err) => {
					// console.log("Route error: " + err);
				});
				this.ACT_FIREBASE_userAuth(null);
			});
		},
	},
};
export default {
	install(Vue) {
		Vue.mixin(mixin);
	},
};
