<template> 
    <v-row no-gutters>

        <!--Page Loader-->
        <pageLoader :is-page-loading="isPageLoading"/>

        <!--Page Content-->
        <v-row  no-gutters style="height: 100%">

            <!--Left Panel-->
            <v-col v-if="computedSplitScreenColumns.left" :cols="computedSplitScreenColumns.left" class="">
               	<div class="pa-4">

                    <!--Page Titile-->
                    <div class="d-flex justify-space-between align-start">
                        <page-title icon="icons8-stopwatch" pageTitle="Sessions" description="Log and view sessions, and also feedback from your coach" :divider="true"/>
                        <app-icon icon="plus" color="primary" size="48" @click.native="openRightPanel('')"/> 
                        <!--@click.native="openRightPanel('')" --> 
                    </div>

                    <div class="d-flex" >
                        <!--Search For Mobile-->
                        <app-input v-if="$vuetify.breakpoint.width <= 700 && sessionsArray.length != 0" input-type="textInput" 
                            append-icon="icons8-search"
                            label="Search"
                            placeholder='Search by session skill, duration'
                            v-model.trim="search"/>
                        
                        <app-input v-if="$vuetify.breakpoint.width > 700" 
                            input-type="textInput"
                            label="Duration"
                            :append-icon="'icons8-search'"
                            style="width:100%;"
                            v-model.trim="searchDuration"/>
                        <app-input v-if="$vuetify.breakpoint.width > 700" 
                            input-type="textInput"
                            class="ml-4 "                             
                            label="Rating"
                            style="width:100%;"
                            :append-icon="'icons8-search'"
                            v-model.trim="searchRating"/>  
                        <app-input  input-type="select" v-if="sessionsArray.length != 0"
                            item-text="name"
                            item-value="name"
                            :items="skillsArray"
                            class="ml-4"                 
                            style="width:100%;"            
                            label='Skill'
                            clearable
                            :append-icon="'icons8-search'"
                            v-model.trim="searchSkill"/>
                        <app-input v-if="$vuetify.breakpoint.width  > 700" 
                            input-type="textInput"
                            class="ml-4"                             
                            label='Date'
                            :append-icon="'icons8-search'"
                            style="width:100%;"
                            v-model.trim="searchDate"/>      

                        <app-input v-if="$vuetify.breakpoint.width > 700" 
                            item-text="userName"
                            item-value="userName"
                            input-type="select"
                            :items="coachArray"
                            style="width:100%;"
                            class="ml-4"                                        
                            label='Coach'
                            :append-icon="'icons8-search'"
                            clearable
                            v-model="searchCoach"/>
                        
                    </div>

                    <!--Table-->
                    <v-data-table v-if="$vuetify.breakpoint.width >= 600"
                        class="rounded-lg mt-4"
                        :headers="computedHeaders"
                        :items="computedTableData"
                        item-key="id"
                        :items-per-page="MIX_itemsPerPage(MIX_breakpoint())"
                        :search="search"
                        sort-by="sessionDate">

                        <!--Session Duration-->
                        <template v-slot:[`item.sessionDuration`]="{ item }">
                            <app-text category="text-small">{{ item.sessionDuration}} mins</app-text>
                        </template>
                        <!--Session Rating-->
                        <template v-slot:[`item.sessionRating`]="{ item }">
                            <app-text category="text-small">{{ item.sessionRating}}</app-text>
                        </template>
                        <!--Session Date-->
                        <template v-slot:[`item.sessionDate`]="{ item }">
                            <app-text category="text-small">{{dayTimeFormat(item.sessionDate, 'long')}}</app-text>
                        </template>
                        
                        <!--Session Skill-->
                        <template v-slot:[`item.sessionSkill`]="{ item }">
                            <app-text category="text-small">{{ item.sessionSkill}} </app-text>
                        </template>
                        <!--Session Coach-->
                        <template v-slot:[`item.sessionCoach`]="{ item }">
                            <app-text category="text-small">{{ item.sessionCoach}}</app-text>
                        </template>
                       
                        <!--Session Feeling-->
                        <template v-slot:[`item.sessionFeeling`]="{ item }">
                            <div>
                                <v-icon v-if="item.sessionFeeling === 'sad'" class="icons8-sad red--text"/>
                                <v-icon v-if="item.sessionFeeling === 'neutral'" class="icons8-neutral neutral--text"/>
                                <v-icon v-if="item.sessionFeeling === 'happy'" class="icons8-happy update--text"/>
                            </div>
                        </template>
                        <!--Actions-->
                        <template v-slot:[`item.actions`]="{item}">
                            <v-btn class="primary--text white"
                                depressed @click="openRightPanel(item)">Open
                                <v-icon class="icons8-forward pa-0" color="primary"/>
                            </v-btn>
                        </template>
                    </v-data-table>

                <!--Mobile Cards-->
                <div v-if="$vuetify.breakpoint.width < 600">

                    <!--No data information-->
                    <div class="text-center" v-if="sessionsArray.length === 0 &&  $vuetify.breakpoint.width <= 600">
                        <app-text category="text-default">  There is nothing to show  </app-text>
                    </div> 

                    <!--Session Card and Details-->
                    <v-card class="my-4 mt-4 pa-4 align-center full-width" @click="openRightPanel(item)" elevation="0" v-for="item in computedTableData" :key="item.id">                     
                        <div class="full-width">
                            <v-row>
                                <v-col cols="12" xs="12" class="d-flex align-center full-width">
                                    <v-row>

                                        <v-row class="ma-0 pa-0"  style="cursor: pointer">
                                            <!-- Session Date-->
                                            <v-col cols="4" xs="4" style="border-right: 1px solid lightgrey" class="d-flex align-center">
                                                <div class="text-center"><v-icon class="primary--text">icons8-calendar-2</v-icon></div>
                                                <app-text category="text-small" class="ml-2 grey--text">{{dayTimeFormat(item.sessionDate, 'short')}}</app-text>
                                            </v-col>
                                            <!-- Session Duration-->
                                            <v-col cols="4" xs="4" style="border-right: 1px solid lightgrey" class="d-flex align-center justify-center">
                                                <div class="text-center"><v-icon size="32" class="primary--text">icons8-stopwatch-2</v-icon></div>
                                                <app-text category="text-small" class="ml-2 grey--text">{{item.sessionDuration}} </app-text>
                                            </v-col>
                                            <!-- Session Rating-->
                                            <v-col cols="4" xs="4" class="d-flex align-center justify-center">
                                                <div class="text-center"><v-icon size="32" class="primary--text">icons8-star</v-icon></div>
                                                <app-text category="text-small" class="ml-2 grey--text">{{item.sessionRating}}/10</app-text>
                                            </v-col>
                                        </v-row>

                                        <!--Sessions Skills-->
                                        <v-col cols="12" xs="12">
                                            <app-text category="text-medium-bold" class="primary--text">{{item.sessionSkill}}</app-text>
                                            <v-divider class="mt-2 mb-1"/>
                                        </v-col>

                                        <!--cHAT LAST MESSAGE-->
                                        <v-col cols="12" xs="12" style="cursor: pointer">
                                            <div class="text--primary d-flex align-center">
                                                <div class="text-center">
                                                    <app-icon size="48" color="grey" class="mr-4"
                                                        :icon="currUserData.userName === item.sessionComments[item.sessionComments.length -1].user ? 'users' : 'whistle'">
                                                    </app-icon>
                                                </div>
                                                <div >
                                                    <span style="text-size-adjust:50%" class="caption font-weight-bold">{{dayTimeMinFormat(item.sessionCreatedAt)}}</span><br/>
                                                    {{returnSubstring(item.sessionComments[item.sessionComments.length -1].content)}}
                                                </div>
                                            </div>
                                        </v-col> 
                
                                    </v-row>											
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>

                    <!--Load more button-->
                    <div cols="12" xs="12" class="d-flex mt-5 align-center justify-center full-width" v-if="computedLoadButton">
                        <div><v-btn width="150" depressed large class="primary" @click="loadMore()">Load More</v-btn></div>
                    </div>

                </div>
            </div>
            </v-col>

            <!--Right panel-->
            <v-col v-if="isRightPanelOpen" :class="computedSplitScreenColumns.left && 'borderLeft'" :cols="computedSplitScreenColumns.right">
                <!--Action Bar-->
                <div class="d-flex align-center justify-space-between appbackground pa-4">

                    <!--Title-->
                    <v-icon class="mr-4" color="primary" size="48">icons8-stopwatch</v-icon>
                    <app-text class="primary--text" category="text-large">
                        {{ selectedItem && selectedItem.sessionSkill ? 'Edit Session' : 'New Session'  }}
                    </app-text>

                    <v-spacer/> 

                    <div>
                        <!--Edit Icon-->
                        <edit-icon v-if="selectedItem != '' && tabs!='feedback'" :isActive="!isFormReadOnly" @click.native="editForm"/>
                        <!-- Delete Icon -->
                        <delete-icon-dialog v-if="selectedItem != '' && tabs!='feedback'" :disabled="!isFormReadOnly" :item-to-delete="selectedItem && selectedItem.sessionSkill" v-on:emitDeleteFromDialog="emittedDeleteFromDialog"/>
                        <!--Close Icon-->
                        <close-icon @click.native="closeRightPanel"/>
                    </div>

                </div>
                <!--Tabs (Overview)-->
                <v-tabs v-model="tabs" dark background-color="primary" show-arrows>
                    <v-tabs-slider color="secondary"/>
                    <v-tab href="#session">
                        <app-text category="text-small">Session</app-text>
                    </v-tab>
                    <v-tab v-if="selectedItem != ''" href="#chat">
                        <app-text category="text-small">Comments</app-text>
                    </v-tab>
                </v-tabs>

                <!--Tab Content-->
                <v-tabs-items v-model="tabs" class="appBackground">

                    <!--Overview Tab Content-->
                    <v-tab-item value="session">
                        <session-form :doesFormStartReadOnly="isFormReadOnly" :formData="selectedItem" v-on:emitCloseRightPanel="emittedCloseRightPanel"/>
                    </v-tab-item>
                    <v-tab-item  value="chat">
                        <chat :chat="selectedItem.sessionComments" :id="selectedItem.id" :sessionOrMatch="true" :creator="selectedItem.userName"/>
                    </v-tab-item>

                </v-tabs-items>
            </v-col>
        </v-row>

    </v-row>
</template>

<script>
    import {mapGetters} from "vuex";
    import SessionForm from '@/views/sessions/sessionForm/sessionForm.vue';
    import Chat from '../chat/chat.vue';

    export default {

        name: "sessions",

        components: { SessionForm, Chat},

        props: ['usersData'],

        data: () => ({

            tabs:0,

            sessionsArray : [],
            reasasibleSessionArray :[],
            selectedItem: {},
            skillsArray:[],
            coachArray:[],

            showMoreButton: true,
            isRightPanelOpen: false,
            isFormReadOnly: true,
            isPageLoading: true,

            currUserData:"",
            search:"",

            //filters
            searchDuration:'',
            searchRating:'',
            searchDate:'',
            searchSkill:'',
            searchCoach:'',

            // Table headers
            headers: [
                { text: "ID", value: "id", align: "left", sortable: false, hidden: true, hide: false, hasAccess: ['Staff-Admin']},
                { text: "Duration", value: "sessionDuration", align: "left", sortable: false, hidden: false, hide: false,},
                { text: "Rating",value: "sessionRating", align: "left", sortable: false, hidden: false, hide: false,},
                { text: "Skill", value: "sessionSkill", align: "left",sortable: false, hidden: false, hide: false,},
                { text: "Date", value: "sessionDate", align: "left", sortable: false, hidden: false, hide: false,},
                { text: "Coach", value: "sessionCoach", align: "left", sortable: false, hidden: false, hide: false,},
                { text: "Feeling",value: "sessionFeeling", align: "left", sortable: false, hidden: false, hide: false,},
                { text: "", value: "actions", align: "right", sortable: false,hasAccess: ['Staff-Admin','Player-User','Coach-User'], hidden: false, hide: false,width: "100px",},
            ],
        }),

        computed: {

            ...mapGetters({
                GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
            }),

            /**
             * Computed Table Data
             *
             * Return a sorted array of JSON objects.
             *
             * @returns {Array} Array of processed JSON data
             */
            computedTableData() {
                const t = this
                let tableData = t.sessionsArray
                if(t.$vuetify.breakpoint.width <= 700 && t.search){
                    tableData = tableData.filter(data =>
                        data.sessionDuration ===parseInt(t.search) ||
                        data.sessionSkill.toUpperCase().match(t.search.toLocaleUpperCase())||
                        data.sessionFeeling.toUpperCase().match(t.search.toLocaleUpperCase())||
                        data.sessionRating.toString().match(t.search))
                }

                if(t.searchDuration){
                    tableData = tableData.filter(data =>
                      data.sessionDuration.toString().match(t.searchDuration))
                }
                if(t.searchRating){
                    tableData = tableData.filter(data =>
                      data.sessionRating.toString().match(t.searchRating))
                }
                if(t.searchCoach){
                    tableData = tableData.filter(data => 
                        data.sessionCoach === t.searchCoach)
                }
                if(t.searchSkill){
                    tableData = tableData.filter(data =>
                      data.sessionSkill.toUpperCase().match(t.searchSkill.toLocaleUpperCase()))
                }
                if(t.searchDate){
                    tableData = tableData.filter(data =>
                    this.$moment(data.sessionDate, 'X').format('dddd Do MMMM \'YY').toUpperCase().match(t.searchDate.toUpperCase()))
                }
                // Sort alphabetically
                tableData = tableData.sort((a, b) => {
                    return a.sessionDate > b.sessionDate ? 1 : -1
                })
                return tableData
            },

            /**
             * Computed Headers
             *
             * Only display headers that are not set to hidden and the user has access to (via userLevel).
             *
             * @returns {[headers]} - filtered headers to render in the table
             */
            computedHeaders() {
                const t = this
                const headers = t.headers
                let filteredHeaders = []
                headers.forEach(header => {
                    // Only Admins can view the OPEN button
                    if (header.value === 'actions' && !header.hasAccess.includes(t.currUserData.userLevel)) {
                        return
                    }
                    // If the column is not hidden, show it
                    if (!header.hidden) {
                        filteredHeaders.push(header) 
                    }
                })
                return filteredHeaders
            },

            /**
             * This function detects if the matches of the user are more than 4 if yes then shows the load more button
             */
            computedLoadButton(){
                if(this.reasasibleSessionArray.length >4 && this.sessionsArray.length !=this.reasasibleSessionArray.length){
                    return true;
                }else{
                    return false;
                }
            },      

            /**
             * Computed Split Screen Columns
             *
             * Calculate the width of the left snd right panels, based on the currently selected functionality.
             * The left panel has the table, the right panel has the forms.
             *
             * @returns {{left: number, right: number}}
             */
            computedSplitScreenColumns() {
                const t = this
                let left = 12
                let right = 0
                if (t.$vuetify.breakpoint.width >= 1200) {
                    if (t.isRightPanelOpen) {
                        left = 6;
                        right = 6;
                    }
                } else {
                    if (t.isRightPanelOpen) {
                        left = 0;
                        right = 12;
                    }
                }
                return {left, right}
            }, 
        },

        methods: {
          
             /**
             *  Returns the last message of the chat 
             * and if it has more than 30 characters reduce it
             */
            returnSubstring(message){
                if(message.length >30){
                    return message.substring(0,30)+".."
                }else{
                    return message
                }
            },

             /**
             * This method return the date in a nice format
             * @return The date
             */
            dayTimeFormat(date, shortLong){
                let formatedDate ="";
                if( shortLong === "short"){
                    formatedDate = this.$moment(date, 'X').format('DD MMM YY');
                }else if(shortLong === 'long'){
                    formatedDate = this.$moment(date, 'X').format('dddd Do MMMM \'YY');
                }
               return formatedDate
            },

            /**
             * This method return the date of the last message in a nice format
             * @return The date of the last message
             */
            dayTimeMinFormat(item){
                return this.$moment(item, 'X').format('DD MMM YY | HH:mm');
            },

            /**
             * This method detects if there are more mattches to be show if yes 
             * load those matches and display them
             */
            loadMore(){
                let remainSessions = this.sessionsArray.length - this.reasasibleSessionArray.length;
                if(remainSessions > 0 && remainSessions> 5){
                    for (var i=0; i<5; i++){
                        this.reasasibleSessionArray.push(this.sessionsArray[this.reasasibleSessionArray+i])
                    }
                }else if(remainSessions <= 0 ){
                    this.showMoreButton = false;
                }else{
                    this.reasasibleSessionArray = this.sessionsArray;
                    this.showMoreButton = false;
                }
            },

            /**
             * lIMITS the possible matches reviews available
             */
            limitSessionView(){
                if(this.sessionsArray.length > 5){
                    for (var i=0; i<5; i++){
                        this.reasasibleSessionArray.push(this.sessionsArray[i])
                    }
                }else{
                    this.reasasibleSessionArray = this.sessionsArray
                }
            },

            /**
             * Close Right Panel
             *
             * When closing the form, reset it to ensure it opens as new.
             */
            closeRightPanel() {
                this.isFormReadOnly = true
                this.isRightPanelOpen = false
            },

            /**
             * Edit Form
             *
             * Toggle the read only state of the form.
             */
            editForm() {
                this.isFormReadOnly = !this.isFormReadOnly;
            },

            /**
             * Emitted Close Right Panel
             *
             * Take the emitted message and call to close the right panel.
             *
             * @returns {Promise<void>}
             */
            async emittedCloseRightPanel() {
                this.readSessions();
                this.closeRightPanel()
                await this.readUserData();
            },

            /**
             * Emitted Delete From Dialog
             *
             * Take the emitted message from the 'deleteIconDialog', and mark the selected document as deleted.
             *
             * @returns {Promise<void>}
             */
            async emittedDeleteFromDialog() {
                await this.MIX_firestore_destroy(this.selectedItem.id, 'sessions');
				await this.MIX_firestore_update(this.GET_FIREBASE_userAuth.uid,"users", "sessionsNo",  this.currUserData.sessionsNo - 1)
                this.readSessions();
                this.closeRightPanel()
                this.MIX_alertBox({ color: "success", timeout: 5000, message: "Session deleted successfully", show: true })
            },

            /**
             * Open Right Panel
             *
             * Open the CRUD form.
             * If the form is opened using the new button, it will be blank.
             * If the form is opened from the table, it will be assigned here and populated.
             *
             * @param item JSON containing the selected data, if required
             */
            openRightPanel(item) {
                const t = this
                if (!item?.id) this.isFormReadOnly = false
                this.selectedItem = item
                this.isRightPanelOpen = true
            },

            /**
             * Reads and sets User data
             */
            async readUserData(){
                let userDataResult = await this.MIX_firestore_read(this.GET_FIREBASE_userAuth.uid, 'users');
                this.currUserData = userDataResult.data;
            },

            async readSkills(){
                this.skillsArray = await this.MIX_firestore_allData('skills');
                this.skillsArray =  this.$sortBy(this.skillsArray, 'name')
            },

            /**
             * Read the sessions that corrispond to the user
             */
            async readSessions(){
                this.sessionsArray = await this.MIX_firestore_readManyWhereCondition("sessions", "userId", "==", this.GET_FIREBASE_userAuth.uid);
                this.limitSessionView();
            },

            async findCoaches(){
                let coach = await this.MIX_firestore_readManyWhereCondition("users", "userLevel", "==", "Coach-User")
                this.coachArray = coach;
                this.coachArray = this.$sortBy(this.coachArray, 'userName');
            }
        },
        async created(){
            this.readUserData();
            this.readSkills();
            this.findCoaches();
            this.readSessions();
            this.isPageLoading=false;
        }

    }
</script>

<style scoped>
    .borderLeft {
        border-left: 4px solid #999999;
        min-height: calc(100vh - 128px);
    }
</style>
