<!--
App Icon

Add all icons here for central management.
Icons take props for:
 - color (the colour for the icon)
 - icon - (the given name for the icon - not the icons8 name)
 - size - (the size for the icon)
-->
<template>
    <div>


        <!-- Logout Icon -->
        <v-icon v-if="icon === 'logout'" class="icons8-logout" :color="color" :size="size"/>

        <!-- Match Icon -->
        <v-icon v-if="icon === 'matchIcon'" class="icons8-rugby-3" :color="color" :size="size"/>

        <!--Delete Icon-->
         <v-icon v-if="icon === 'delete'" class="icons8-trash" :color="color" :size="size"/>

        <!--Neutral Face-->
        <v-icon v-if="icon === 'neutralFeeling'" class="icons8-neutral" :color="color" :size="size"/>

        <!--Happy Face-->
        <v-icon v-if="icon === 'happyFeeling'" class="icons8-happy" :color="color" :size="size"/>

        <!--Sad Face-->
        <v-icon v-if="icon === 'sadFeeling'" class="icons8-sad" :color="color" :size="size"/>

        <!--Star-->
        <v-icon v-if="icon === 'star'" class="icons8-star" :color="color" :size="size"/>

        <!--Suspended-->
        <v-icon v-if="icon === 'suspended'" class="icons8-private-2" :color="color" :size="size"/>

          <!--Idp Uncompleted-->
        <v-icon v-if="icon === 'idpUncomplete'" class="icons8-macos-close" :color="color" :size="size"/>

        <!--Archived-->
        <v-icon v-if="icon === 'archived'" class="icons8-xbox-a" :color="color" :size="size"/>

        <!--Checkmark Yes-->
        <v-icon v-if="icon === 'checkmarkYes'" class="icons8-checkmark-yes" :color="color" :size="size"/>
        <!--Cancel-->
        <v-icon v-if="icon === 'cancel'" class="icons8-cancel" :color="color" :size="size"/>

        <!--Close-->
        <v-icon v-if="icon === 'close'" class="icons8-close" :color="color" :size="size"/>

        <!--Edit-->
        <v-icon v-if="icon === 'edit'" class="icons8-edit" :color="color" :size="size"/>

        <!--Export CSV-->
        <v-icon v-if="icon === 'exportCSV'" class="icons8-export-csv" :color="color" :size="size"/>

        <!--Pending-->
        <v-icon v-if="icon === 'pending'" class="icons8-inactive-state" :color="color" :size="size"/>

        <!--Rugby Ball-->
        <v-icon v-if="icon === 'rugbyBall'" class="icons8-rugby-2" :color="color" :size="size"/>

        <!--Stopwatch-->
        <v-icon v-if="icon === 'stopwatch'" class="icons8-stopwatch-2" :color="color" :size="size"/>

        <!--Plus/New-->
        <v-icon v-if="icon === 'plus'" class="icons8-plus-2" :color="color" :size="size"/>

        <!--Fatique-->
        <v-icon v-if="icon === 'fatique'" class="icons8-sleep" :color="color" :size="size"/>

        <!--Performance-->
        <v-icon v-if="icon === 'performance'" class="icons8-speed" :color="color" :size="size"/>

        <!--Send-->
        <v-icon v-if="icon === 'send'" class="icons8-send" :color="color" :size="size"/>

        <!--Save-->
        <v-icon v-if="icon === 'save'" class="icons8-save" :color="color" :size="size"/>

        <!--Calendar-->
        <v-icon v-if="icon === 'calendar'" class="icons8-calendar-2" :color="color" :size="size"/>

        <!--Whistle-->
        <v-icon v-if="icon === 'whistle'" class="icons8-whistle-3" :color="color" :size="size"/>

        <!--Ok -->
        <v-icon v-if="icon === 'ok'" class="icons8-ok" :color="color" :size="size"/>

        <!--Password invisibility-->
        <v-icon v-if="icon === 'invisible'" class="icons8-invisible" :color="color" :size="size"/>

        <!--Password visibility-->
        <v-icon v-if="icon === 'visibility'" class="icons8-eye" :color="color" :size="size"/>

        <!--Search-->
        <v-icon v-if="icon === 'search'" class="icons8-search" :color="color" :size="size"/>

        <!--Users-->
        <v-icon v-if="icon === 'users'" class="icons8-user-2" :color="color" :size="size" />

        <!--Menu-->
        <v-icon v-if="icon === 'menu'" class="icons8-menu-rounded" :color="color" :size="size"/>

         <!--Home-->
        <v-icon v-if="icon === 'home'" class="icons8-home-page" :color="color" :size="size"/>

        <!--Grid-->
        <v-icon v-if="icon === 'grid'" class="icons8-grid-2" :color="color" :size="size"/>

        <!--Team-->
        <v-icon v-if="icon === 'team'" class="icons8-team" :color="color" :size="size"/>
        
        <!--Vertical Menu-->
        <v-icon v-if="icon === 'vertical-menu'" class="icons8-menu-vertical" :color="color" :size="size"/>

        <!--Return button-->
        <v-icon v-if="icon === 'goBack'" class="icons8-go-back" :color="color" :size="size"/>
    </div>
</template>

<script>
export default {

    name: "AppIcon",

    props: ['color', 'icon', 'size'],

}
</script>

<style scoped>

</style>
