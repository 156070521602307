<!--
User Profile

Render the user's profile and schedule data.
From here the user can adjust their details and also be advised on missing information.
-->
<template>
    <div class="pa-4" style="width: 100%">
        <!--Header (Title | Instructional text)-->
            <div class="d-flex justify-space-between align-start">

                <!--Page title-->
                <page-title icon="icons8-user-2" page-title="My Profile" description="This is your profile.
                From here you can update your personal details."  :divider="true"/>

                <!--Edit-->
                <edit-icon @click.native="editDocument"  :isActive="!isFormReadOnly"/>

            </div>
            
        <!--Page Loader-->
        <pageLoader :is-page-loading="isPageLoading"/>
        <!--Form-->
        <user-form v-if="!isPageLoading" :doesFormStartReadOnly="isFormReadOnly" :formData="formData"/>
        
    </div>
</template>



<script>
import userForm from '@/views/users/userForm/userForm.vue'
import {mapGetters} from "vuex";

export default {
    name: "userProfile",
    components: {
        userForm,
    },
    data: () => ({
        isPageLoading: true,
        isFormReadOnly: true,
        formData : {},
        
    }),

    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
        }),
    },

    methods: {
        /**
         * Edit Document
         *
         * Set the form to an editable state.
         */
        editDocument() {
            const t = this
            t.isFormReadOnly = !t.isFormReadOnly; 
        },
    },
    async created(){
        this.userDataResult = await this.MIX_firestore_read(this.GET_FIREBASE_userAuth.uid, 'users');
        this.formData = this.userDataResult.data
        this.isPageLoading = false;
    },
}
</script>

